<template>
  <div>
    <b-card>
      <b-input-group class="mb-3">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="검색어" v-model="form.list.q" @keypress.enter="list" v-focus></b-form-input>
      </b-input-group>
      <b-row>
        <b-col cols="6">
          <brand-preset v-model="form.list.brand" :hideDisabled="true" :hideOptions="true"></brand-preset>
        </b-col>
        <b-col cols="6">
          <category-preset v-model="form.list.category"></category-preset>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="2">
          <small>SKIP</small>
          <b-form-input :number="true" v-model="form.list.skip"></b-form-input>
        </b-col>
        <b-col cols="2">
          <small>LIMIT</small>
          <b-form-input :number="true" v-model="form.list.limit"></b-form-input>
        </b-col>
        <b-col cols="2">
          <small>B 최저가</small>
          <b-form-checkbox v-model="form.list.rank"></b-form-checkbox>
        </b-col>
      </b-row>

      <small>정렬기준</small>
      <b-form-radio-group class="col-form-label" v-model="form.list.sort" :options="options.sort"></b-form-radio-group>

      <b-button class="m-1" variant="primary" @click="list" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
    </b-card>

    <b-card>
      <b-row>
        <b-col cols="6">
          <b>ASIS</b> <span v-if="item.asis.hits">took: {{item.asis.took}} ms, total: {{item.asis.hits.total}}</span>
          <template v-if="item.asis.hits">
            <div class="flex-row flex-wrap d-flex">
              <es-result class="flex-grow-0 position-relative" style="width: 33.3%" v-for="e in item.tobe.hits.hits.map(e => e._source)"
                         v-bind="{e, cfMap, sortKey: form.list.sort}" :key="e.goodsno"></es-result>
            </div>
          </template>
        </b-col>
        <b-col cols="6">
          <b>TOBE</b> <span v-if="item.tobe.hits">took: {{item.tobe.took}} ms, total: {{item.tobe.hits.total.value}}</span>
          <template v-if="item.tobe.hits">
            <div class="flex-row flex-wrap d-flex">
              <es-result class="flex-grow-0 position-relative" style="width: 33.3%" v-for="e in item.tobe.hits.hits.map(e => e._source)"
                         v-bind="{e, cfMap, sortKey: form.list.sort}" :key="e.goodsno"></es-result>
            </div>
          </template>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import esResult from '@/views/goods/ES/ESResult.vue'

export default {
  name: 'SimulateGoods',
  title: 'ES 비교',
  components: {esResult},
  data() {
    return {
      shop: [],
      shopMap: {},
      cfMap: {},
      form: {
        list: {
          q: '',
          brand: [],
          category: [],
          skip: 0,
          limit: 30,
          rank: false,
          sort: 'rank_sum',
        }
      },
      lastBody: {list: {}},
      item: {asis: {}, tobe: {}},
      busy: {list: false, listmore: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController

      options: {
        sort: [
          {text: '없음', value: ''},
          {text: 'rank_sum', value: 'rank_sum'},
          {text: 'best_rank', value: 'best_rank'},
          {text: '최신순', value: 'new'},
          {text: '낮은 가격순', value: 'lowPrice'},
          {text: '높은 가격순', value: 'highPrice'},
          {text: '할인율순', value: 'sale'},

          // 신규 ES 적용항목 : pv_2day,cart_24hour,wish_24hour,review_24hour,sale_24hour,pay_24hour,rank_1hour,rank_1day,rank_1week,rank_1month
          {text: 'PV 2일', value: 'pv_2day'},
          {text: '장바구니 24시간', value: 'cart_24hour'},
          {text: '위시리스트 24시간', value: 'wish_24hour'},
          {text: '리뷰 24시간', value: 'review_24hour'},
          {text: '판매수 24시간', value: 'sale_24hour'},
          {text: '판매금액 24시간', value: 'pay_24hour'},
          {text: '랭킹 1시간', value: 'rank_1hour'},
          {text: '랭킹 1일', value: 'rank_1day'},
          {text: '랭킹 1주', value: 'rank_1week'},
          {text: '랭킹 1개월', value: 'rank_1month'},
        ]
      }
    }
  },
  async created() {
    const meta = await this.$api.getMeta('shop');
    if (!meta) return;

    this.shop = meta.shop.sort((a, b) => (a.use_yn === 'n' ? 10000 : 0) + a.shop_id - (b.use_yn === 'n' ? 10000 : 0) - b.shop_id);
    this.shop.forEach(s => {
      s.value = s.shop_id;
      s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });

  },
  methods: {
    async list() {
      this.busy.list = true;
      const j = await this.$api.postJson('/goods/es/ES', {
        ...this.form.list,
        brand: this.form.list.brand.map(e => e.brand_no),
        category: this.form.list.category.map(e => e.category)
      });
      this.busy.list = false;
      this.item.asis = j.asis;
      this.item.tobe = j.tobe;
      if ('pv_2day,cart_24hour,wish_24hour,review_24hour,sale_24hour,pay_24hour,rank_1hour,rank_1day,rank_1week,rank_1month'.split(',').includes(this.form.list.sort)) {
        this.item.asis = {};
      }

      const goodsNos = j.asis.hits.hits.concat(j.tobe.hits.hits).map(e => e._source.goodsno).set();
      const targetGoodsNos = goodsNos.filter(e => !this.cfMap[e]);
      if (targetGoodsNos.length === 0) {
        return;
      }
      const cfs = await this.$api.postJson('/goods/confirmed', {search: '', goods_no_include: targetGoodsNos});
      Object.assign(this.cfMap, this.$utils.arr2map(cfs.list, 'goods_no'));
      cfs.list.forEach(e => {
        // N 최저가 스트링 생성, 타 상품 있을때만
        if (e.naver && e.naver.other_product_cnt) {
          e.nStr = `N ${e.naver.rank}위(${this.$moment(e.naver._dt).fromNow()})`;
        }
      });
      this.$forceUpdate();
    },
  }
}
</script>

<style scoped>

</style>
