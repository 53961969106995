<template>
  <div>
    <div class="position-relative">
      <a :href="MALL_URL + '/m2/goods/view.php?goodsno=' + e.goodsno" target="_blank">
        <img :src="e.img_i.split('|')[0].replace('.jpg', '_200.webp')" style="width: 100%; display: block; margin: auto" :style="{maxWidth: `${imgWidth}px`}">
      </a>
      <!-- 좌상단 정보 -->
      <div v-if="info && e.score" class="position-absolute" style="top: 0;left: 0;line-height: 15px">
        <b-badge variant="light" title="PageView">PV: {{e.score[`pv_${day}d`]}}</b-badge><br/>
        <b-badge variant="light" title="SaleCount">SC: {{e.score[`sale_cnt_${day}d`]}}</b-badge><br/>
        <b-badge variant="light" title="Wishlist">WI: {{e.score[`wish_${day}d`]}}</b-badge><br/>
        <b-badge variant="light" title="Cart">CA: {{e.score[`cart_${day}d`]}}</b-badge><br/>
        <b-badge variant="light" title="TimeScore">T: {{e.score[`time_score_${day}d`]}}</b-badge><br/>
        <b-badge variant="light" title="AgeDays">AGE: {{e.score[`age_days_${day}d`]}}</b-badge><br/>
      </div>
      <!-- 우상단 정보 -->
      <div v-if="info" class="position-absolute text-right" style="top: 0;right: 0;line-height: 15px">
        <a :href="`/#/goods/${e.goodsno}`" target="_blank" class="badge badge-primary">{{e.goodsno}} <i class="fa fa-external-link"></i></a><br/>
        <template v-if="cfMap[e.goodsno] && (cfMap[e.goodsno].matched_sku_id || cfMap[e.goodsno].sku_id)">
          <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(cfMap[e.goodsno].matched_sku_id || cfMap[e.goodsno].sku_id)}`"
             v-b-tooltip="cfMap[e.goodsno].matched_sku_id || cfMap[e.goodsno].sku_id"
             class="badge badge-success" target="_blank">
            Naver 검색 <i class="fa fa-external-link"></i>
          </a><br/>
        </template>
<!--        <template v-if="cfMap[e.goodsno]">
          <span class="badge alert-warning">{{cfMap[e.goodsno].goods_id}}, {{cfMap[e.goodsno].manual ? '파센' : 'FEED'}}</span><br/>
          <template v-if="cfMap[e.goodsno].sku_id">
            <span class="badge alert-success">SKU: {{cfMap[e.goodsno].sku_id}}</span><br/>
          </template>
          <template v-if="cfMap[e.goodsno].matched_sku_id">
            <span class="badge alert-info">통합 SKU: {{cfMap[e.goodsno].matched_sku_id}}</span><br/>
          </template>
        </template>-->
      </div>
      <!-- 좌하단 정보 -->
      <div v-if="info" class="position-absolute" style="left: 0;bottom: 0;line-height: 15px">
        <template v-if="e.is_boutique">
          <span class="badge badge-success">부티크 상품</span><br/>
        </template>
        <template v-if="e.naver.rank === 1">
          <span class="badge badge-dark mr-1">B 최저가</span>
        </template>
        <template v-if="e.express">
          <span class="badge badge-info mr-1">Express</span>
        </template>
      </div>
      <!-- 우하단 정보 -->
      <div v-if="info" class="position-absolute text-right" style="right: 0;bottom: 0;line-height: 15px">
        <b-badge variant="primary" class="pointer" @click="$emit('scoreModal', e)">Score: {{Math.round(e.sort[0])}}</b-badge><br/>
        <b-badge variant="secondary" class="pointer" @click="$modal.show({title: 'JSON 보기', item: e, type: 'json'})">JSON</b-badge><br/>
      </div>
    </div>

    <div class="info_box_wrap mb-2">
      <div v-if="info" class="clearfix">
        <div class="pull-right">
          <b-badge variant="success">{{e.shop.id}}. {{shopMap[e.shop.id].boutique}}</b-badge>
          <span :class="`badge alert-${e.delivery_type === '국내' ? 'info' : 'success'}`">{{e.delivery_type}}</span>
        </div>
        <b-badge variant="warning">{{e.brand.name}}</b-badge>
      </div>
      <div class="goodsnm" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" :title="e.goodsnm">{{e.goodsnm}}</div>
      <div class="price_info_wrap" style="display: inline-flex;">
        <div v-if="e.member_price < e.consumer" class="sale_percent mr-2" style="color: #bd081c;font-weight: bold;">{{Math.round(100 - e.member_price / e.consumer * 100)}}%</div>
        <div class="mr-2 price" style="font-weight: bold;color: #020202;">{{e.member_price.comma()}}</div>
        <div class="no_sale_price" style="color: #bdbdbd;text-decoration: line-through;">{{e.consumer.comma()}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ESResult',
  props: ['e', 'shopMap', 'cfMap', 'info', 'imgWidth', 'day'],
  data() {
    return {
    }
  },
  async created() {
  },
  methods: {
  }
}
</script>
