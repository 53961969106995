<template>
  <div>
    <div class="position-relative">
      <a :href="MALL_URL + '/m2/goods/view.php?goodsno=' + e.goodsno" target="_blank">
        <img v-if="cfMap[e.goodsno]" :src="cfMap[e.goodsno].img_urls[0].replace('.jpg', '_200.webp')">
        <img v-else src="https://i.balaan.io/blank/noimg_goods_200.webp">
      </a>
      <!-- 좌상단 정보 -->
      <div class="position-absolute" style="top: 0;left: 0;line-height: 15px">
        <template v-if="e.score && e.score.goodsno">
          <b-badge variant="light" title="PayAmount">AMT: {{$utils.round((e.score[`pay_amount`] || 0) / 1000000, 1)}}M</b-badge><br/>
          <b-badge variant="light" title="GoodsPrice">PRI: {{e.score[`goods_price`] ? `${$utils.round(e.score[`goods_price`] / 1000, 1)}K` : ''}}</b-badge><br/>
          <b-badge variant="light" title="CVR per PV">CVR/PV: {{e.score[`cvr_per_pv`]}}</b-badge><br/>
          <b-badge variant="light" title="Cart">CA: {{e.score[`cart`]}}</b-badge><br/>
          <b-badge variant="light" title="Wishlist">WI: {{e.score[`wish`]}}</b-badge><br/>
          <b-badge variant="light" title="PV">PV: {{e.score[`pv`]}}</b-badge><br/>
        </template>
      </div>
      <!-- 우상단 정보 -->
      <div class="position-absolute text-right" style="top: 0;right: 0;line-height: 15px">
        <a :href="`/#/goods/${e.goodsno}`" target="_blank" class="badge badge-primary">{{e.goodsno}} <i class="fa fa-external-link"></i></a><br/>
        <template v-if="cfMap[e.goodsno] && (cfMap[e.goodsno].matched_sku_id || cfMap[e.goodsno].sku_id)">
          <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(cfMap[e.goodsno].matched_sku_id || cfMap[e.goodsno].sku_id)}`"
             v-b-tooltip="cfMap[e.goodsno].matched_sku_id || cfMap[e.goodsno].sku_id"
             class="badge badge-success" target="_blank">
            Naver 검색 <i class="fa fa-external-link"></i>
          </a><br/>
        </template>
      </div>
      <!-- 좌하단 정보 -->
      <div class="position-absolute" style="left: 0;bottom: 0;line-height: 15px">
        <template v-if="e.is_boutique">
          <span class="badge badge-success">부티크 상품</span><br/>
        </template>
        <template v-if="e.b_lowest_price === 1">
          <span class="badge badge-dark mr-1">B 최저가</span>
        </template>
        <template v-if="e.express">
          <span class="badge badge-info mr-1">Express</span>
        </template>
      </div>
      <!-- 우하단 정보 -->
      <div class="position-absolute text-right" style="right: 0;bottom: 0;line-height: 15px">
        <b-badge variant="secondary" class="pointer" @click="$modal.show({title: 'JSON 보기', item: e, type: 'json'})">JSON</b-badge><br/>
      </div>
    </div>

    <div class="info_box_wrap mb-2">
      <div class="clearfix">
        <b-badge variant="success">{{e.shop_id}}. {{shopMap[e.shop_id].boutique}}</b-badge>
        <div class="pull-right">
          <span :class="`badge alert-${e.delivery_type === '국내' ? 'info' : 'success'}`">{{e.delivery_type}}</span>
        </div>
      </div>
      <div class="goodsnm" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" :title="e.goodsnm">{{e.goodsnm}}</div>
      <div class="price_info_wrap" style="display: inline-flex;">
        <div v-if="e.member_price < e.consumer" class="sale_percent mr-2" style="color: #bd081c;font-weight: bold;">{{Math.round(100 - e.member_price / e.consumer * 100)}}%</div>
        <div class="mr-2 price" style="font-weight: bold;color: #020202;">{{e.member_price.comma()}}</div>
        <div class="no_sale_price" style="color: #bdbdbd;text-decoration: line-through;">{{e.consumer.comma()}}</div>
      </div>
    </div>
    <div style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">
      <span v-for="opt in e.option" class="badge badge-secondary mr-1" :key="opt.size">{{opt.size}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GMGoods',
  props: ['e', 'shopMap', 'cfMap', 'day'],
  data() {
    return {
    }
  },
  async created() {
  },
  methods: {
  }
}
</script>
