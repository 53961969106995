<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab title="카탈로그 검색">
        <search-g-m></search-g-m>
      </b-tab>
      <b-tab title="카탈로그 시뮬">
        <simulate-g-m v-if="loaded[1] || tabIndex === 1"></simulate-g-m>
      </b-tab>
      <b-tab title="상품 시뮬">
        <simulate-goods v-if="loaded[2] || tabIndex === 2"></simulate-goods>
      </b-tab>
<!--      <b-tab title="ES 비교">
        <e-s-diff v-if="loaded[3] || tabIndex === 3"></e-s-diff>
      </b-tab>-->
    </b-tabs>

  </div>
</template>

<script>
import SearchGM from './SearchGM';
import SimulateGoods from './SimulateGoods';
import SimulateGM from './SimulateGM';
import ESDiff from './ESDiff';

export default {
  name: 'ES',
  title: '검색엔진',
  components: {SearchGM, SimulateGoods, SimulateGM, ESDiff},
  data() {
    return {
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
}
</script>
