<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="8">
          <b-input-group class="mb-3">
            <b-input-group-prepend>
              <b-button variant="primary" @click="simulAll()" :disabled="busy.simul">
                <i class="fa fa-search"></i> 검색
                <b-spinner class="ml-1" small v-if="busy.simul"></b-spinner>
              </b-button>
            </b-input-group-prepend>
            <b-form-input type="text" placeholder="검색어" v-model="form.simul.q" @keypress.enter="simulAll()" v-focus></b-form-input>
          </b-input-group>
          <b-row>
            <b-col cols="6">
              <category-preset :hide-options="true" v-model="form.simul.category"></category-preset>
            </b-col>
            <b-col cols="6">
              <brand-preset v-model="form.simul.brand" :hideDisabled="true" :hideOptions="true"></brand-preset>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col cols="3">
              <small>가격조건</small><br/>
              <b-input-group>
                <b-form-input :number="true" placeholder="최소가" v-model="form.simul.min"></b-form-input>
                <b-input-group-append>
                  <b-button variant="primary"><i class="fa fa-exchange"></i></b-button>
                </b-input-group-append>
                <b-form-input :number="true" placeholder="최대가" v-model="form.simul.max"></b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="9" xl="5">
              <small>상품유형</small><br/>
              <b-form inline>
                <b-form-radio-group class="col-form-label" v-model="form.simul.goodsType" :options="[
                    {text: '전체', value: 'ALL'},
                    {text: '새상품만', value: 'new'},
                    {text: '빈티지만', value: 'used'}
                  ]"></b-form-radio-group>
                <template v-if="form.simul.goodsType === 'used'">
                  <b-button class="mr-1" size="sm" variant="light" @click="toggleUsedGrade()">전체</b-button>
                  <b-button class="mr-1" size="sm" variant="primary" @click="toggleUsedGrade('S')">S</b-button>
                  <b-button class="mr-1" size="sm" variant="success" @click="toggleUsedGrade('A')">A</b-button>
                  <b-button class="mr-2" size="sm" variant="warning" @click="toggleUsedGrade('B')">B</b-button>
                  <b-form-checkbox-group v-model="form.simul.usedGrade">
                    <b-form-checkbox v-for="s in $C.USED_GRADE" :key="s.value" :value="s.value" :title="s.desc">{{s.text}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </template>
              </b-form>
            </b-col>
            <b-col cols="3" xl="1">
              <small>Color 선택</small><br/>
              <color-checkbox class="mt-1" v-model="form.simul.colors"></color-checkbox>
            </b-col>
            <b-col cols="1">
              <small>B 최저가</small>
              <b-form-checkbox class="col-form-label" v-model="form.simul.rank"></b-form-checkbox>
            </b-col>
            <b-col cols="1">
              <small>오늘도착</small>
              <b-form-checkbox class="col-form-label" v-model="form.simul.oneday_delivery"></b-form-checkbox>
            </b-col>
            <b-col cols="1">
              <small>Express</small>
              <b-form-checkbox class="col-form-label" v-model="form.simul.express"></b-form-checkbox>
            </b-col>
          </b-row>

          <small>컬러</small><br/>
          <div class="clearfix">
            <b-button class="pull-left mr-2" size="sm" variant="primary" @click="e=>{ if (form.simul.colors === $C.COLORS.length) form.simul.colors = []; else form.simul.colors = $C.COLORS.map(e=>e.name); }">전체</b-button>
            <b-form-checkbox-group class="pull-left mt-1" name="group" v-model="form.simul.colors">
              <b-form-checkbox v-for="c in $C.COLORS" :key="c.name" :value="c.name">
                <div class="d-inline-block" :style="{backgroundColor:'#'+c.color, width:'22px', height:'22px'}" v-b-tooltip="c.name_ko"></div>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </div>

          <hr />

          <b-row class="mb-2">
            <b-col cols="2">
              <small>기준 기간</small><br/>
              <b-form inline>
                <b-form-select v-model="form.simul.day" :options="options.sort" @change="getESAggr()"></b-form-select>
              </b-form>
            </b-col>
            <b-col cols="3">
              <small>모드</small><br/>
              <b-form-radio-group class="col-form-label" v-model="form.simul.mode" :options="options.mode"></b-form-radio-group>
            </b-col>
            <b-col cols="2">
              <small>Rank Normalizer</small>
              <i class="fa fa-question-circle ml-1"
                 v-b-tooltip="'랭킹은 해당하는 상품의 총량에 따라 달라지기에 pv 와 판매량을 비교한다면 pv 가 일반적으로 더 큰 값을 갖습니다. 이를 보정하여 각 팩터별 최대치를 동일하게 맞춥니다.'"></i>
              <br/>
              <b-form-radio-group class="col-form-label" v-model="form.simul.rank_normalizer" :options="options.rank_normalizer"></b-form-radio-group>
            </b-col>
            <b-col cols="2">
              <small>페이지당 상품수</small><br/>
              <b-form-input v-model="form.simul.limit" class="w-65px text-center"></b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <b-tabs>
            <b-tab title="인기검색어">
              <div v-for="k in searchMeta.keyword" :key="k.dt">
                <b>{{k.dt}}</b><br/>
                <template v-for="w in k.words">
                  <span class="pointer badge badge-light fs-13 mr-1 mb-1" :title="w.cnt + '회'" @click="setKeyword(w.word)" :key="w.word">{{w.word}}</span>
                </template>
                <hr/>
              </div>
              <a href="/#/data/store/140" target="_blank">기간별 검색어 순위 조회 <i class="fa fa-external-link"></i></a>
            </b-tab>
            <b-tab title="Top Brand">
              <div class="mb-2">
                <b>{{form.simul.day}} 일간 판매금액 순(취소 포함)</b>
              </div>
              <template v-if="brand.length">
                <div class="mb-1 pointer clearfix" v-for="(b, idx) in searchMeta.brand.slice((searchMeta.brandPage - 1) * searchMeta.limit, searchMeta.brandPage * searchMeta.limit)" @click="setBrand(b)" :key="idx">
                  <div class="pull-right">
                    <b-badge class="fs-12" variant="light" :title="`QTY: ${b.qty}`">SALE: {{$utils.round(b.sales / 1000000, 1)}}백만</b-badge>
                    <!--                    <b-badge class="fs-12" variant="light">QTY: {{b.qty}}</b-badge>-->
                  </div>
                  <b-badge class="fs-13" variant="light">{{(searchMeta.brandPage - 1) * searchMeta.limit + idx + 1}}위</b-badge>
                  <b-badge class="fs-13" variant="warning">{{ brandMap[b.brand_no].brand_nm }} ({{ brandMap[b.brand_no].brand_nm_kr }})</b-badge>
                </div>
                <div class="d-flex justify-content-center mt-3">
                  <b-pagination :total-rows="searchMeta.brand.length" :per-page="searchMeta.limit" :limit="10" hide-goto-end-buttons v-model="searchMeta.brandPage"/>
                </div>
              </template>
            </b-tab>
            <b-tab title="Top Category">
              <div class="mb-2">
                <b>{{form.simul.day}} 일간 판매금액 순(취소 포함)</b>
              </div>
              <template v-if="category.length">
                <div class="mb-1 pointer clearfix" v-for="(c, idx) in searchMeta.category.slice((searchMeta.categoryPage - 1) * searchMeta.limit, searchMeta.categoryPage * searchMeta.limit)" @click="setCategory(c)" :key="idx">
                  <div class="pull-right">
                    <b-badge class="fs-12" variant="light" :title="`QTY: ${c.qty}`">SALE: {{$utils.round(c.sales / 1000000, 1)}}백만</b-badge>
                    <!--                    <b-badge class="fs-12" variant="light">QTY: {{c.qty}}</b-badge>-->
                  </div>
                  <b-badge class="fs-13" variant="light">{{(searchMeta.categoryPage - 1) * searchMeta.limit + idx + 1}}위</b-badge>
                  <span class="badge alert-primary fs-13">{{ categoryMap[c.category].path }}</span>
                </div>
                <div class="d-flex justify-content-center mt-3">
                  <b-pagination :total-rows="searchMeta.category.length" :per-page="searchMeta.limit" :limit="10" hide-goto-end-buttons v-model="searchMeta.categoryPage"/>
                </div>
              </template>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>


      <div class="mt-2 text-center">
        <b-button size="lg" class="mr-2 w-200px" variant="warning" @click="resetForm">
          초기화
        </b-button>
        <b-button size="lg" class="w-300px" variant="primary" @click="simulAll" :disabled="busy.simul">
          전체검색<b-spinner class="ml-1" small v-if="busy.simul"></b-spinner>
        </b-button>
      </div>

      <hr />

      <h6>정렬방식 설정</h6>

      <b-row>
        <b-col cols="4">
          <b>ASIS</b><br/>
          <b-row>
            <b-col cols="6" v-for="c in cols.filter(e => e.asis)" :key="c.key" class="mb-2">
              <div>{{ c.name }}</div>
              <b-form inline>
                <b-form-input v-model="form.asis[c.col]" class="w-65px text-center form-control-sm"></b-form-input>
                <span :title="factorFnTooltip(c.col, 'rank_')">x Rank</span>
              </b-form>
            </b-col>
          </b-row>
          <b-btn-group>
            <b-btn variant="light" @click="copySetting('asis')">
              Copy
            </b-btn>
            <b-btn variant="light" @click="openPasteModal('asis')">
              Paste
            </b-btn>
            <b-btn variant="primary" @click="simul('asis')" :disabled="busy.simul">
              검색<b-spinner class="ml-1" small v-if="busy.simul"></b-spinner>
            </b-btn>
          </b-btn-group>
        </b-col>
        <b-col cols="4" v-for="f in ['a', 'b']" :key="f">
          <b>{{f.toUpperCase()}}</b><br/>
          <b-tabs v-model="form[f].sortTab">
            <b-tab title="필드별 우선순위">
              <v-select v-model="form[f].sortCols" label="label" index="value" :options="options.sortCols" multiple placeholder="정렬 기준 필드를 순서에 맞게 넣어주세요">
                <template v-slot:no-options="{ search, searching }">
                  <em style="opacity: 0.5;">지정된 필드를 입력해주세요.</em>
                </template>
              </v-select>
              <hr/>
              <b-btn class="mb-2 mr-2" size="sm" variant="warning" @click="form[f].sortCols.splice(0, 10)">초기화</b-btn>
              <b-btn class="mb-2 mr-2" size="sm" :variant="form[f].sortCols.includes(c.value) ? 'success' : 'light'" v-for="c in options.sortCols"
                     @click="toggleSortCol(form[f], c.value)" :title="c.attr ? '기간과 무관한 상품속성입니다.' : `${form.simul.day}일 기준입니다.`" :key="c.value">
                {{ c.label }}
                <i v-if="c.attr" class="fa fa-exclamation-circle"></i>
              </b-btn>
            </b-tab>
            <b-tab title="필드 가중치">
              <b-row>
                <b-col cols="6" v-for="c in cols" :key="c.key" class="mb-2">
                  <div>{{ c.name }}</div>
                  <div class="clearfix">
                    <b-btn-group size="sm" class="pull-right">
                      <b-btn v-for="fn in factorFn" :variant="form[f][`${c.col}_fn`] === fn.prefix ? 'primary' : 'light'" :key="fn.name"
                             @click="form[f][`${c.col}_fn`] = fn.prefix" :title="factorFnTooltip(c.col, fn.prefix)">{{ fn.name }}
                      </b-btn>
                    </b-btn-group>
                    <b-form-input v-model="form[f][c.col]" class="w-65px text-center form-control-sm"></b-form-input>
                  </div>
<!--                  <vue-slider ref="slider" v-model="form[f][c.col]" v-bind="sliderOptions"></vue-slider>-->
                </b-col>
              </b-row>

              <b-btn-group size="sm">
                <b-btn variant="light" @click="setRandom('cat', f)">
                  <i class="fa fa-2x">😺</i>
                </b-btn>
                <b-btn variant="light" @click="setRandom('dog', f)">
                  <i class="fa fa-2x">🐶</i>
                </b-btn>
                <b-btn variant="light" @click="setRandom('monkey', f)">
                  <i class="fa fa-2x">🐵</i>
                </b-btn>
              </b-btn-group>
            </b-tab>
          </b-tabs>

          <b-btn-group class="mt-2">
            <b-btn variant="light" @click="copySetting(f, $event)">
              Copy
            </b-btn>
            <b-btn variant="light" @click="openPasteModal(f)">
              Paste
            </b-btn>
            <b-btn variant="primary" @click="simul(f)" :disabled="busy.simul">
              검색<b-spinner class="ml-1" small v-if="busy.simul"></b-spinner>
            </b-btn>
          </b-btn-group>
        </b-col>
      </b-row>

      <hr />

      <h6>Look & Feel</h6>
      <b-row class="mb-2">
        <b-col cols="2">
          <b-form-checkbox class="col-form-label" v-model="info"> 부가정보 보이기</b-form-checkbox>
        </b-col>
        <b-col cols="2">
          <b-form inline>
            이미지 Width
            <b-form-input class="w-90px mx-1 text-center" v-model="imgWidth"></b-form-input>
            px
          </b-form>
        </b-col>
      </b-row>
    </b-card>

    <div class="d-flex justify-content-center mb-2">
      <b-pagination :total-rows="totalRows" :per-page="form.simul.limit" :limit="20" v-model="form.simul.page" @change="simulAll()"/>
    </div>

    <b-card>
      <b-row>
        <b-col cols="4">
          <div class="clearfix mb-2">
            <b-badge variant="warning" class="pull-right pointer" @click="item.asis = {}">Clear</b-badge>
            <b-badge class="fs-15">ASIS</b-badge> <span v-if="item.asis.hits">took: {{item.asis.took}} ms, total: {{item.asis.hits.total.value}}</span>
          </div>
          <template v-if="item.asis.hits">
            <div class="flex-row flex-wrap d-flex">
              <es-result class="flex-grow-0 position-relative" style="width: calc(50% - 10px)" :style="{marginRight: idx % 2 === 0 ? `20px` : 0}"
                         v-for="(e, idx) in item.asis.hits.hits.map(e => ({...e._source, sort: e.sort}))"
                         v-bind="{e, shopMap, cfMap, info, imgWidth, day: form.simul.day}" @scoreModal="openScoreModal($event, 'asis')" :key="e.goodsno"></es-result>
            </div>
          </template>
        </b-col>
        <b-col v-for="f in ['a', 'b']" :key="f" cols="4" style="border-left: 10px solid #c8ced3;">
          <div class="clearfix mb-2">
            <b-badge variant="warning" class="pull-right pointer" @click="item[f] = {}">Clear</b-badge>
            <b-badge class="fs-15 mr-2" variant="success">{{f.toUpperCase()}}</b-badge>
            <span v-if="item[f].hits">took: {{item[f].took}} ms, total: {{item[f].hits.total.value}}</span>
          </div>
          <template v-if="item[f].hits">
            <div class="flex-row flex-wrap d-flex">
              <es-result class="flex-grow-0 position-relative" style="width: calc(50% - 10px)" :style="{marginRight: idx % 2 === 0 ? `20px` : 0}"
                         v-for="(e, idx) in item[f].hits.hits.map(e => ({...e._source, sort: e.sort}))"
                         v-bind="{e, shopMap, cfMap, info, imgWidth, day: form.simul.day}" @scoreModal="openScoreModal($event, f)" :key="e.goodsno"></es-result>
            </div>
          </template>
        </b-col>
      </b-row>
    </b-card>

    <div class="d-flex justify-content-center mb-2">
      <b-pagination :total-rows="totalRows" :per-page="form.simul.limit" :limit="20" v-model="form.simul.page" @change="simulAll()"/>
    </div>

    <b-modal title="Score Factor" size="xl" v-model="modal.score">
      <template v-if="modalItem.score && modalItem.score.score">
        <h5>Score : {{JSON.stringify(modalItem.score.sort)}}</h5>
        <div v-html="modalItem.scoreScript.str"></div>
        <br/>
        <h5>Score Factor of Current Goods({{lastBody.simul.day}} days)</h5>
        <table class="table b-table table-sm text-center mb-5">
          <thead>
          <tr>
            <th></th>
            <th v-for="fn in factorFn" :key="fn.name">
              {{fn.name}}
            </th>
            <th v-if="scoreFactorGroup[lastBody.simul.day]">Normalized Rank</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="c in colsAll" :key="c.col">
            <th>{{c.name}}</th>
            <td v-for="fn in factorFn" :key="fn.name">
              {{modalItem.score.score[`${fn.prefix}${c.col}_${lastBody.simul.day}d`]}}
            </td>
            <td v-if="scoreFactorGroup[lastBody.simul.day]">
              <span v-if="modalItem.score.score[`rank_${c.col}_${lastBody.simul.day}d`]">
                {{$utils.round(modalItem.score.score[`rank_${c.col}_${lastBody.simul.day}d`] * 10 / scoreFactorGroup[lastBody.simul.day][`count_${c.col}`], 6)}}
              </span>
            </td>
          </tr>
          <tr>
            <th>Age(days)</th>
            <td>{{modalItem.score.score[`age_days_${lastBody.simul.day}d`]}}</td>
            <td colspan="4"></td>
          </tr>
          </tbody>
        </table>

        <template v-if="scoreFactorGroup[lastBody.simul.day]">
          <h5>Score Factor Group of All Goods({{lastBody.simul.day}} days)</h5>
          <table class="table b-table table-sm text-center">
            <thead>
            <tr>
              <th></th>
              <th v-for="fn in groupFn" :key="fn">
                {{fn}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="c in colsAll" :key="c.col">
              <th>{{c.name}}</th>
              <td v-for="fn in groupFn" :key="fn">
                {{scoreFactorGroup[lastBody.simul.day][`${fn}_${c.col}`]}}
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </template>
    </b-modal>

    <b-modal title="설정 붙여넣기" size="lg" v-model="modal.paste" @ok="pasteSetting()">
      <b-textarea v-model="modalItem.paste.text" rows="10"></b-textarea>
    </b-modal>
  </div>
</template>

<script>
import vueSlider from 'vue-slider-component'
import esResult from '@/views/goods/ES/ESResult.vue'
import ColorCheckbox from "../../modules/ColorCheckbox.vue";

export default {
  name: 'SimulateGoods',
  title: 'ES 비교',
  components: {ColorCheckbox, vueSlider, esResult},
  data() {
    return {
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      cfMap: {},
      dict: {},
      cols: [
        {col: 'pv', name: 'PV', asis: true},
        {col: 'sale_cnt', name: '판매 수량', asis: true},
        {col: 'pay_amount', name: '거래액', asis: true},
        {col: 'goods_price', name: '상품 단가', asis: true},
        {col: 'cart', name: '쇼핑백'},
        {col: 'wish', name: '위시리스트'},
        {col: 'cvr_per_pv', name: 'PV 대비 CVR'},
      ],
      colsAll: [], // time_score 포함
      factorFn: [
        {prefix: '', name: 'RAW'},
        {prefix: 'rank_', name: 'Rank'},
        {prefix: 'zscore_', name: 'Z'},
        {prefix: 'zpscore_', name: 'ZP'},
      ],
      factorFnMap: {},
      defaultFactorFn: {
        pv: 'zpscore_',
        sale_cnt: 'zpscore_',
        pay_amount: 'zpscore_',
        goods_price: 'zpscore_',
        cart: 'zpscore_',
        wish: 'zpscore_',
        cvr_per_pv: 'zpscore_',
      },
      groupFn: 'std,avg,min,max,count,min_rank,max_rank,min_zscore,max_zscore,min_zpscore,max_zpscore'.split(','),
      defaultForm: {
        simul: {
          q: '',
          brand: [],
          category: [],
          colors: [],
          min: '',
          max: '',
          day: 1,
          mode: 'normal',
          rank_normalizer: 'N',
          goodsType: 'ALL',
          usedGrade: this.$C.USED_GRADE.map(e => e.value),
          // skip: 0,
          limit: 30,
          page: 1,
          rank: false,
          oneday_delivery: false,
          express: false,
        },
        score: {
          sortTab: 0,
          sortCols: ['pay_amount', 'goods_price', 'cvr_per_pv', 'cart', 'wish', 'pv', 'goods_price']
        }
      },
      scoreFactorGroup: {

      },
      currentGroup: {},
      searchMeta: {
        keyword: [],
        brand: [],
        category: [],
        limit: 10,
        brandPage: 1,
        categoryPage: 1,
      },
      form: {
        simul: {},
        asis: {},
        a: {},
        b: {}
      },
      lastBody: {simul: {}},
      item: {asis: {}, a: {}, b: {}},
      busy: {simul: false, simulmore: false},
      hasMore: {simul: false},
      ac: {simul: null}, // abortController
      modal: {
        score: false,
        paste: false,
      },
      modalItem: {
        score: {},
        scoreScript: {},
        paste: {
          form: '',
          text: '',
        }
      },
      totalRows: 1,
      info: true,
      imgWidth: 200,

      sliderOptions: {
        height: 3,
        dotSize: 10,
        min: 0,
        max: 100,
        interval: 1,
        speed: 0.1,
        tooltip: false,
      },
      options: {
        sort: [
          {text: '1일', value: 1, sortKey: 'rank_1day'},
          {text: '1주', value: 7, sortKey: 'rank_1week'},
          {text: '2주', value: 14, sortKey: 'rank_2week'},
          {text: '3주', value: 21, sortKey: 'rank_3week'},
          {text: '1개월', value: 30, sortKey: 'rank_1month'},
        ],
        sortCols: [

        ],
        mode: [
          {text: '일반모드', value: 'normal'},
          {text: '부티크 혼합모드', value: 'boutique'},
        ],
        rank_normalizer: [
          {text: '사용', value: 'Y'},
          {text: '미사용', value: 'N'},
        ]
      }
    }
  },
  async created() {
    this.getScoreFactorGroup();
    this.getSearchMeta();
    this.getESAggr();
    this.getDict();

    this.resetForm();

    const meta = await this.$api.getMeta('shop,brand,category');
    if (!meta) return;

    this.shop = meta.shop.sort((a, b) => (a.use_yn === 'n' ? 10000 : 0) + a.shop_id - (b.use_yn === 'n' ? 10000 : 0) - b.shop_id);
    this.shop.forEach(s => {
      s.value = s.shop_id;
      s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });

    this.brand = meta.brand.map(e => {
      return this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a, b) => a.label.localeCompare(b.label));

    this.category = meta.category.map(e => {
      return this.categoryMap[e.category] = {...e, value: e.category, label: `${e.category} (${e.category_nm})`};
    }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));
    meta.category.forEach(e => {
      this.categoryMap[e.category].path = Array(e.category.length / 3).fill(0)
        .map((n, i) => this.categoryMap[e.category.substring(0, i * 3 + 3)].category_nm).join(' > ');
    });

    this.cols.forEach(c => {
      if (c.asis) this.form.asis[c.col] = 1;
      this.defaultForm.score[c.col] = 10;
      this.defaultForm.score[c.col + '_fn'] = this.defaultFactorFn[c.col] || '';
    });
    this.colsAll = [...this.cols, {col: 'time_score', name: 'Time(Aging)'}];
    this.options.sortCols = this.cols.map(e => ({label: e.name, value: e.col})).concat([
      {label: '발란코드', value: 'goodsno', attr: true},
      {label: '옵션중최저가', value: 'member_price', attr: true},
    ]);

    this.factorFnMap = {...this.$utils.arr2map(this.factorFn, 'prefix'), ...this.$utils.arr2map(this.factorFn, 'name')};

    this.form.a = this.$utils.clone(this.defaultForm.score);
    this.form.b = this.$utils.clone(this.defaultForm.score);
  },
  watch: {
    form: {
      deep: true,
      handler: function () {
        this.currentGroup = this.scoreFactorGroup[this.form.simul.day];
      }
    }
  },
  methods: {
    async getDict() {
      this.busy.simul = true;
      const j = await this.$api.getJson('/goods/es/dict');
      this.dict = j.dict;
      this.busy.simul = false;
    },
    /**
     * 선택한 기준 기간의 그룹함수 결과를 가져온다
     * @returns {Promise<void>}
     */
    async getScoreFactorGroup() {
      const j = await this.$api.getJson('/goods/es/scoreFactorGroup');
      this.scoreFactorGroup = this.$utils.arr2map(j.data, 'day');
      this.currentGroup = this.scoreFactorGroup[this.form.simul.day];
    },
    /**
     * 검색 프리셋용 인기 키워드를 가져온다.
     * @returns {Promise<void>}
     */
    async getSearchMeta() {
      const j = await this.$api.getJson('/goods/es/searchMeta');
      this.searchMeta.keyword = j.data.keyword;
    },
    /**
     * 검색 프리셋용 인기 브랜드, 카테고리를 가져온다.
     * @returns {Promise<void>}
     */
    async getESAggr() {
      this.$api.postJson('/goods/es/elasticCloud', {index: 'bl_order', query: this.makeOrderStatQuery('category')}).then(res => {
        this.searchMeta.category = res.data.aggregations.group.buckets.map(e => ({category: e.key, sales: e.sales.value, qty: e.qty.value}));
      });
      this.$api.postJson('/goods/es/elasticCloud', {index: 'bl_order', query: this.makeOrderStatQuery('brand_no')}).then(res => {
        this.searchMeta.brand = res.data.aggregations.group.buckets.map(e => ({brand_no: e.key, sales: e.sales.value, qty: e.qty.value}));
      });
    },
    makeOrderStatQuery(groupBy) {
      const esQuery = {
        query: {
          bool: {
            filter: [
              {
                range: {
                  order_date: {
                    gte: this.$utils.kstD(this.$moment().add(-this.form.simul.day, 'day'))
                  }
                }
              }
            ]
          }
        },
        size: 0,
        aggs: {
          group: {
            terms: {
              field: groupBy,
              order: {
                sales: 'desc'
              },
              size: 100
            },
            aggs: {
              sales: {
                sum: {
                  field: 'sales_price',
                }
              },
              qty: {
                sum: {
                  field: 'qty'
                }
              }
            }
          },
        },
      };
      return esQuery;
    },
    setKeyword(w) {
      this.form.simul.q = w;
      this.simulAll();
    },
    setBrand(b) {
      this.form.simul.brand = [this.brandMap[b.brand_no]];
      this.simulAll();
    },
    setCategory(c) {
      this.form.simul.category = [this.categoryMap[c.category]];
      this.simulAll();
    },
    resetForm() {
      this.form.simul = this.$utils.clone(this.defaultForm.simul);
    },
    toggleSortCol(form, col) {
      if (form.sortCols.includes(col)) {
        form.sortCols.splice(form.sortCols.indexOf(col), 1);
      } else {
        form.sortCols.push(col);
      }
    },
    /**
     * col 과 fnPrefix 가 주여졌을 때 tooltip 내용을 생성한다.
     *
     * @param col
     * @param fnPrefix
     */
    factorFnTooltip(col, fnPrefix) {
      if (!this.currentGroup) return ''; // not yet
      if (fnPrefix === '') { // RAW or Rank
        return `avg: ${this.currentGroup[`avg_${col}`]}\n` +
          `count: ${this.currentGroup[`count_${col}`]}\n` +
          `std: ${this.currentGroup[`std_${col}`]}\n` +
          `min: ${this.currentGroup[`min_${col}`]}\n` +
          `max: ${this.currentGroup[`max_${col}`]}\n`;
      } else if (fnPrefix === 'zscore_' || fnPrefix === 'zpscore_') {
        return `zscore min: ${this.currentGroup[`min_zscore_${col}`]}\n` +
          `zscore max: ${this.currentGroup[`max_zscore_${col}`]}\n` +
          `zpscore min: ${this.currentGroup[`min_zpscore_${col}`]}\n` +
          `zpscore max: ${this.currentGroup[`max_zpscore_${col}`]}\n`;
      } else if (fnPrefix === 'rank_') {
        return `count: ${this.currentGroup[`count_${col}`]}\n` +
          `min_rank: ${this.currentGroup[`min_rank_${col}`]}\n` +
          `max_rank: ${this.currentGroup[`max_rank_${col}`]}\n`;
      }
    },
    setRandom(animal, form) {
      if (animal === 'cat') { // 전체 랜덤
        this.cols.forEach(c => {
          this.form[form][c.col] = Math.round(Math.random() * 100);
        });
      } else if (animal === 'dog') { // 랜덤한 몇개를 소소하게 변경
        const cols = this.cols.filter(() => Math.random() < 0.5);
        cols.forEach(c => {
          this.form[form][c.col] = this.form[form][c.col] + Math.floor(Math.random() * 10 - 5);
          this.form[form][c.col] = Math.max(Math.min(this.form[form][c.col], 100), 0); // 바운더리 적용
        });
      } else if (animal === 'monkey') { // 한 개를 랜덤하게 변경
        const c = this.cols[Math.floor(Math.random() * this.cols.length)];
        this.form[form][c.col] = Math.round(Math.random() * 100);
      }
    },
    copySetting(form, event) {
      if (event && event.shiftKey) { // query 를 카피
        this.$utils.copyAlert(JSON.stringify(this.makeESQuery(form), null, 2));
      } else if (event && event.ctrlKey) { // elastic repo 에 적용할 multiple 카피
        const setting = this.cols.map(c => {
          const multiple = this.form[form][c.col];
          return `    ${this.form[form][c.col + '_fn']}${c.col}: ${multiple},`
        }).join('\n');
        this.$utils.copyAlert(setting);
      } else if (form === 'asis') {
        const setting = this.cols.filter(e => e.asis).map(c => {
          const multiple = this.form[form][c.col];
          return `${c.col}: Rank * ${multiple}`
        }).join('\n');
        this.$utils.copyAlert(setting);
      } else {
        const setting = this.cols.map(c => {
          const fn = this.factorFnMap[this.form[form][c.col + '_fn']];
          const multiple = this.form[form][c.col];
          return `${c.col}: ${fn.name} * ${multiple}`
        }).join('\n');
        this.$utils.copyAlert(setting);
      }
    },
    openPasteModal(form) {
      this.modalItem.paste.form = form;
      this.modalItem.paste.text = '';
      this.modal.paste = true;
    },
    pasteSetting() {
      const form = this.modalItem.paste.form;
      const text = this.modalItem.paste.text.trim();
      text.split(/\r?\n/).forEach(line => {
        const [, col, fn, multiple] = line.match(/^(.*): (.*) \* (.*)$/);
        this.form[form][col] = multiple;
        if (form !== 'asis') this.form[form][`${col}_fn`] = this.factorFnMap[fn].prefix;
      });
    },
    makeESQuery(sortForm) {
      // 조건이 하나라도 있다면 query.bool 부터 시작
      const form = this.form.simul;
      const esQuery = {query: {bool: {must: [], must_not: [], filter: []}}, from: (form.page - 1) * form.limit, size: form.limit};
      const bool = esQuery.query.bool;

      if (form.q.trim()) {
        bool.must = bool.must.concat(this.parseSearchQuery(form.q));
        // bool.must.push({match: {search_fields: form.q.trim()}});
      }
      if (form.category.length) bool.filter.push({terms: {category: form.category.map(e => e.category)}});
      if (form.brand.length) bool.filter.push({terms: {'brand.id': form.brand.map(e => e.brand_no)}});
      if (form.rank) bool.filter.push({term: {'naver.rank': 1}});
      if (form.oneday_delivery) bool.filter.push({term: {oneday_delivery: 1}});
      if (form.express) bool.filter.push({term: {express: 1}});
      if (form.colors.length) bool.filter.push({terms: {major_color: form.colors}});
      if (form.min || form.max) {
        const range = {};
        if (form.min) range.gte = form.min;
        if (form.max) range.lte = form.max;
        bool.filter.push({range: {member_price: range}});
      }
      if (form.goodsType !== 'ALL') bool.filter.push({term: {'goods_status': form.goodsType[0].toUpperCase()}});
      if (form.goodsType === 'used') bool.filter.push({terms: {'used_grade': form.usedGrade}});

      if (bool.must.length === 0) delete bool.must;
      if (bool.must_not.length === 0) delete bool.must_not;
      if (bool.filter.length === 0) delete bool.filter;
      if (Object.keys(bool).length === 0) delete esQuery.query;

      if (sortForm.in('a', 'b')) {
        const day = this.form.simul.day;
        const form = this.form[sortForm];
        if (form.sortTab === 0) {
          esQuery.sort = form.sortCols.map(e => {
            if (this.cols.find(c => c.col === e)) {
              return {[`score.${e}_${day}d`]: 'desc'};
            }
            return {[e]: 'desc'};
          });
          this.modalItem.scoreScript[sortForm] = form.sortCols.map(e => this.options.sortCols.find(c => c.value === e).label).join(', ');
        } else {
          const scores = [];
          const sigma = this.cols.map(c => {
            const fn = form[c.col + '_fn'];
            const multiple = form[c.col];
            const field = `score.${fn}${c.col}_${day}d`;
            scores.push(`${c.name} ${this.factorFnMap[fn].name}(<b>$${fn}${c.col}</b>) * <b>${multiple}</b>`);
            return `(!doc['${field}'].empty ? ${fn === 'rank_' ? '-' : ''}doc['${field}'].value : 0) * ${multiple}` +
              (this.form.simul.rank_normalizer === 'Y' && fn === 'rank_' && this.scoreFactorGroup[day] ?
                ` / ${this.scoreFactorGroup[day]['count_' + c.col] / 10}` : '');
          }).join(' + ');
          const source = `(${sigma}) * (!doc['score.time_score_${day}d'].empty ? doc['score.time_score_${day}d'].value : 0.25)`;
          esQuery.sort = {
            _script: {
              type: 'number',
              script: {
                lang: 'painless',
                source
              },
              order: 'desc'
            }
          };
          this.modalItem.scoreScript[sortForm] = '(' + scores.join(' + ') + ') * Time Score(<b>$time_score</b>)';
        }
      } else if (sortForm === 'asis') {
        const day = this.form.simul.day;
        const scores = [];
        const sigma = this.cols.filter(e => e.asis).map(c => {
          const multiple = this.form.asis[c.col];
          const field = `score.rank_${c.col}_${day}d`;
          scores.push(`${c.name} Rank(<b>$rank_${c.col}</b>) * <b>${multiple}</b>`);
          return `(!doc['${field}'].empty ? doc['${field}'].value : 0) * ${multiple}` +
            (this.form.simul.rank_normalizer === 'Y' && this.scoreFactorGroup[day] ? ` / ${this.scoreFactorGroup[day]['count_' + c.col] / 10}` : '');
        }).join(' + ');
        esQuery.sort = {
          _script: {
            type: 'number',
            script: {
              lang: 'painless',
              source: sigma
            },
            order: 'asc'
          }
        };
        this.modalItem.scoreScript.asis = scores.join(' + ');
      }

      return esQuery;
    },
    async simulAll() {
      setTimeout(() => {
        this.simul('asis');
        this.simul('a');
        this.simul('b');
      }, 0);
    },
    async simul(form) {
      this.busy.simul = true;
      this.lastBody.simul = this.$utils.clone(this.form.simul);
      this.lastBody[form] = this.$utils.clone(this.form[form]);
      if (this.form.simul.mode === 'normal') {
        const j = await this.$api.postJson('/goods/es/simul', {index: 'bl_goods', query: this.makeESQuery(form)});
        if (!j) {
          this.busy.simul = false;
          return;
        }
        this.item[form] = j.data;
      } else if (this.form.simul.mode === 'boutique') { // 부티크 혼합 모드
        const query = this.makeESQuery(form);

        if (!query.query) query.query = {bool: {must: []}};
        if (!query.query.bool.must) query.query.bool.must = [];
        const partSize = query.size / 2; // 개별 파트의 사이즈, 60 -> 30
        const partFrom = query.from / 2; // 개별 파트의 from, 600 -> 300
        query.size = partSize;
        query.from = partFrom;
        const nonBoutiqueQuery = this.$utils.clone(query);
        query.query.bool.must.push({term: {is_boutique: 1}});
        nonBoutiqueQuery.query.bool.must.push({term: {is_boutique: 0}});

        // 각각의 쿼리로 데이터 추출
        let [boutiqueRaw, nonBoutiqueRaw] = await Promise.all([
          this.$api.postJson('/goods/es/simul', {index: 'bl_goods', query}),
          this.$api.postJson('/goods/es/simul', {index: 'bl_goods', query: nonBoutiqueQuery}),
        ]);
        if (!boutiqueRaw || !nonBoutiqueRaw) {
          this.busy.simul = false;
          return;
        }
        boutiqueRaw = boutiqueRaw.data;
        nonBoutiqueRaw = nonBoutiqueRaw.data;
        const boutiqueCount = boutiqueRaw.hits.total.value;
        const nonBoutiqueCount = nonBoutiqueRaw.hits.total.value;

        if (boutiqueRaw.hits.hits.length < query.size / 2 && nonBoutiqueRaw.hits.hits.length >= query.size / 2) {
          const moreCount = partFrom + partSize - boutiqueCount;
          nonBoutiqueQuery.from = Math.max(partFrom + moreCount - partSize, 0);
          nonBoutiqueQuery.size = Math.min(partSize * 2, partSize + moreCount);
          nonBoutiqueRaw = (await this.$api.postJson('/goods/es/simul', {query: nonBoutiqueQuery})).data;
        } else if (nonBoutiqueRaw.hits.hits.length < query.size / 2 && boutiqueRaw.hits.hits.length >= query.size / 2) {
          const moreCount = partFrom + partSize - nonBoutiqueCount;
          query.from = Math.max(partFrom + moreCount - partSize, 0);
          query.size = Math.min(partSize * 2, partSize + moreCount);
          boutiqueRaw = (await this.$api.postJson('/goods/es/simul', {query})).data;
        }

        const total = boutiqueCount + nonBoutiqueCount;
        const hits = [];
        for (let i = 0; i < Math.max(boutiqueRaw.hits.hits.length, nonBoutiqueRaw.hits.hits.length); i++) {
          nonBoutiqueRaw.hits.hits[i] && hits.push(nonBoutiqueRaw.hits.hits[i]);
          boutiqueRaw.hits.hits[i] && hits.push(boutiqueRaw.hits.hits[i]);
        }

        boutiqueRaw.hits.total.value = total;
        boutiqueRaw.hits.hits = hits;
        this.item[form] = boutiqueRaw;
      }
      this.busy.simul = false;
      this.totalRows = this.item[form].hits.total.value;

      // sort 값을 정비한다.
      // sort 에 해당하는 필드값이 null 이면 극단값인 -9223372036854776000 등으로 교체되어 정렬된다.
      // 오해의 소지가 있으므로 100억을 넘기면 0 으로 교체한다.
      this.item[form].hits.hits.forEach(e => {
        e.sort = e.sort.map(s => s > 10000000000 || s < -10000000000 ? 0 : s);
      });

      const targetGoodsNos = this.item[form].hits.hits.map(e => e._source.goodsno).set().filter(e => !this.cfMap[e]);
      if (targetGoodsNos.length === 0) {
        return;
      }
      const cfs = await this.$api.postJson('/goods/es/confirmed', {goodsNos: targetGoodsNos});
      Object.assign(this.cfMap, this.$utils.arr2map(cfs.list, 'goods_no'));

      this.$forceUpdate();
    },
    openScoreModal(e, form) {
      this.modalItem.score = e;
      const str = this.modalItem.scoreScript[form];
      if (this.lastBody[form].sortTab === 0) {
        this.modalItem.scoreScript.str = str;
      } else {
        const cols = str.match(/\$\w+/g);
        this.modalItem.scoreScript.str = [str, ...cols].reduce((a, b) => a.replace(b, e.score[`${b.slice(1)}_${this.form.simul.day}d`]));
      }
      this.modal.score = true;
    },


    /**
     * 검색어를 의미단위에 맞게 사전을 기준으로 쪼갠다.
     * 띄어쓰기가 없어도 쪼개는 것을 목표로 한다.
     * godo_api 와 동일하게 맞춘다.
     *
     * @param {string} q
     * @return {[object[], object[]]}
     */
    parseSearchQuery(q) {
      if (!q) return [[], []];
      const re = /[\s\-\]\\`~!@#$%^&*()=+[{}|;",<>/?°]+/g; // es 에서 분리해서 저장하는 기준
      const search = q.trim();
      if (!search) return [[], []];

      const dict = this.dict;
      const must = [];

      const tokens = search.split(re);
      const splitter = search.match(re);
      const getOrgIndex = idx => {
        let tokenLen = 0;
        let splitterLen = 0;
        for (let i = 0; i < tokens.length; i++) {
          tokenLen += tokens[i].length;
          if (idx < tokenLen) break;
          splitterLen += splitter[i].length;
        }
        return idx + splitterLen;
      };

      const joinWord = search.split(re).join('');
      // 2 ~ 36 자 까지 잘라서 비교 후 없으면 index 를 증가하며 비교한다. 긴 순서부터 진행한다.
      // 36 자는 가장 긴 사전단어(브랜드)인 ANDREAS KRONTHALER FOR VIVIENNE WESTWOOD 기준이다.
      // 찾으면 잘라서 must 에 넣는다. 만약 첫 단어는 매칭되지 않았다면 중간단어가 매칭될 때 첫 단어도 넣어준다. ex) 가가구찌 => 가가, 구찌
      let idxStart = 0;
      let hasBrand = false; // 나이키 x 제이크루 등의 상품명은 둘 다 브랜드라서 and 조건으로 들어가지 않도록 브랜드는 하나만 넣어야 한다.
      let hasCategory = false; // 카테고리도 마찬가지로 and 로 들어가면 안된다.
      for (let idx = 0; idx < joinWord.length; idx++) {
        for (let i = Math.min(36, joinWord.length - idx); i >= 2; i--) {
          const chunk = joinWord.slice(idx, idx + i);
          let obj = dict[chunk];
          if (obj) {
            if (idx - idxStart > 0) {
              // 중간 단어부터 검색되었다면, 스페이스 등의 기호를 복구하는 것을 고려한다.
              // 그러나 sku 등 영문, 숫자로만 되어있다면 붙여서 검색한다.
              const befWord = joinWord.slice(idxStart, idx);
              if (befWord.match(/^[- \w]+$/)) {
                if (isNaN(+search) && isNaN(+befWord)) {
                  must.push({match: {search_fields: befWord}});
                } else {
                  must.push({bool: {should: [{match: {search_fields: befWord}}, {match: {search_fields: search}}], minimum_should_match: 1}});
                }
              } else {
                const t = search.slice(getOrgIndex(idxStart), getOrgIndex(idx));
                must.push({match: {search_fields: t}});
              }
            }
            if (obj.length > 1) { // 사전 중복 매칭 관리
              const should = [];
              if (!hasBrand && obj.some(e => e.brand_no)) { // 브랜드에 대한 직접검색, ex) 제이린드버그 검색시 ES 사전에 의해 제이 캣 등도 검색된다.
                should.push({terms: {'brand.id': obj.map(e => e.brand_no).filter(e => e)}});
                hasBrand = true;
              }
              obj = obj.filter(e => !e.brand_no);

              if (!hasCategory && obj.some(e => e.category)) { // 카테고리에 대한 직접검색, ex) 토트 백 검색시 ES 사전에 의해 백 이 검색된다.
                // catnm: chunk 로 직접 검색해도 되지만 샌들 / 슬리퍼 같은 카테고리에 대응되지 않는다.
                should.push({terms: {category: obj.map(e => e.category).filter(e => e)}});
                hasCategory = true;
              }
              obj = obj.filter(e => !e.category);

              if (obj.length) {
                should.push({match: {search_fields: obj.map(e => e.text).set().join(' ')}});
              }

              if (!isNaN(+search) && !isNaN(+chunk)) {
                should.push({match: {search_fields: search}});
              }

              must.push({bool: {should, minimum_should_match: 1}});
            } else {
              if (!hasBrand && obj[0].brand_no) { // 브랜드에 대한 직접검색, ex) 제이린드버그 검색시 ES 사전에 의해 제이 캣 등도 검색된다.
                must.push({term: {'brand.id': obj[0].brand_no}});
                hasBrand = true;
              } else if (!hasCategory && obj[0].category) { // 카테고리에 대한 직접검색, ex) 토트 백 검색시 ES 사전에 의해 백 이 검색된다.
                must.push({term: {'category': obj[0].category}});
                hasCategory = true;
              } else {
                // must.push({match: {search_fields: obj[0].text}});
                must.push({bool: {should: [{match: {search_fields: obj[0].text}}, {match: {search_fields: search}}], minimum_should_match: 1}});
              }
            }

            idx = idx + i - 1;
            idxStart = idx + 1;
            break;
          }
        }
      }
      if (idxStart < joinWord.length) {
        // 남는 단어가 있다면, 스페이스 등의 기호를 복구하는 것을 고려한다.
        // 그러나 sku 등 영문, 숫자로만 되어있다면 붙여서 검색한다.
        const aftWord = joinWord.slice(idxStart);
        if (aftWord.match(/^[- \w]+$/)) {
          // 문자가 같이 섞여있을 경우에만 넣어주고
          // 검색어가 숫자로만 구성되면 발란코드로 취급한다.
          if (isNaN(+search)) {
            must.push({match: {search_fields: aftWord}});
          } else {
            must.push({bool: {should: [{match: {search_fields: aftWord}}, {match: {search_fields: search}}], minimum_should_match: 1}});
          }
        } else {
          must.push({match: {search_fields: search.slice(getOrgIndex(idxStart))}});
        }
      }
      return must;
    },
    toggleUsedGrade(grade) {
      if (!grade) {
        this.form.simul.usedGrade = this.form.simul.usedGrade.length === this.$C.USED_GRADE.length ? [] : this.$C.USED_GRADE.map(e => e.value);
      } else {
        this.form.simul.usedGrade = this.$C.USED_GRADE.filter(e => e.value[0] === grade).map(e => e.value);
      }
    },
  }
}
</script>

<style scoped>

</style>
