<template>
  <div>
    <b-card>
      <b-collapse id="form" v-model="collapse.form">
        <b-row>
          <b-col cols="12" xl="8">
            <b-input-group class="mb-3">
              <b-input-group-prepend>
                <b-button variant="primary" @click="list()" :disabled="busy.gm">
                  <i class="fa fa-search"></i> 검색
                  <b-spinner class="ml-1" small v-if="busy.gm"></b-spinner>
                </b-button>
              </b-input-group-prepend>
              <b-form-input type="text" placeholder="검색어" v-model="form.gm.q" @keypress.enter="list()" v-focus></b-form-input>
            </b-input-group>
            <b-row>
              <b-col cols="12" lg="6">
                <category-preset :hide-options="true" v-model="form.gm.category"></category-preset>
              </b-col>
              <b-col cols="12" lg="6">
                <brand-preset class="mt-3 mt-lg-0" v-model="form.gm.brand" :hideDisabled="true" :hideOptions="true"></brand-preset>
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col cols="3" xl="2">
                <small>정렬기준</small><br/>
                <b-form-select v-model="form.gm.sort" :options="options.sort"></b-form-select>
              </b-col>
              <b-col cols="6" xl="4">
                <small>가격조건</small><br/>
                <b-input-group>
                  <b-form-input :number="true" placeholder="최소가" v-model="form.gm.min"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="fa fa-exchange"></i></b-button>
                  </b-input-group-append>
                  <b-form-input :number="true" placeholder="최대가" v-model="form.gm.max"></b-form-input>
                </b-input-group>
              </b-col>
              <b-col cols="3" xl="2">
                <small>페이지당 상품수</small><br/>
                <b-form-input v-model="form.gm.limit" class="w-65px text-center"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="3" xl="2">
                <small>Color 선택</small><br/>
                <color-checkbox v-model="form.gm.colors"></color-checkbox>
              </b-col>
              <b-col cols="9" xl="5">
                <small>상품유형</small><br/>
                <b-form inline>
                  <b-form-radio-group class="col-form-label" v-model="form.gm.goodsType" :options="[
                    {text: '전체', value: 'ALL'},
                    {text: '새상품만', value: 'new'},
                    {text: '빈티지만', value: 'used'}
                  ]"></b-form-radio-group>
                  <template v-if="form.gm.goodsType === 'used'">
                    <b-button class="mr-1" size="sm" variant="light" @click="toggleUsedGrade()">전체</b-button>
                    <b-button class="mr-1" size="sm" variant="primary" @click="toggleUsedGrade('S')">S</b-button>
                    <b-button class="mr-1" size="sm" variant="success" @click="toggleUsedGrade('A')">A</b-button>
                    <b-button class="mr-2" size="sm" variant="warning" @click="toggleUsedGrade('B')">B</b-button>
                    <b-form-checkbox-group v-model="form.gm.usedGrade">
                      <b-form-checkbox v-for="s in $C.USED_GRADE" :key="s.value" :value="s.value" :title="s.desc">{{s.text}}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </template>
                </b-form>
              </b-col>
              <b-col cols="3" xl="1">
                <small>카탈로그만</small>
                <b-form-checkbox class="col-form-label" v-model="form.gm.gmOnly"></b-form-checkbox>
              </b-col>
              <b-col cols="3" xl="1">
                <small>B 최저가</small>
                <b-form-checkbox class="col-form-label" v-model="form.gm.rank"></b-form-checkbox>
              </b-col>
              <b-col cols="3" xl="1">
                <small>오늘도착</small>
                <b-form-checkbox class="col-form-label" v-model="form.gm.oneday_delivery"></b-form-checkbox>
              </b-col>
              <b-col cols="3" xl="1">
                <small>Express</small>
                <b-form-checkbox class="col-form-label" v-model="form.gm.express"></b-form-checkbox>
              </b-col>
            </b-row>

            <hr/>
            <h6>검색 프리셋</h6>
            <b-row>
              <b-col cols="12" lg="4" v-for="cate in [{c: '009', v: 'success'}, {c: '010', v: 'primary'}, {c: '011', v: 'warning'}]" :key="cate.c">
                <b-card>
                  <div slot="header">
                    <strong>{{categoryMap[cate.c] ? categoryMap[cate.c].category_nm : ''}}</strong>
                  </div>
                  <b-btn size="sm" class="mr-1 mb-1" :variant="`outline-${cate.v}`" @click="setForm('ranking', cate.c)">랭킹</b-btn>
                  <b-btn size="sm" class="mr-1 mb-1" :variant="`outline-${cate.v}`" @click="setForm('cart100', cate.c)">장바구니 TOP 100</b-btn>
                  <b-btn size="sm" class="mr-1 mb-1" :variant="`outline-${cate.v}`" @click="setForm('wish100', cate.c)">위시리스트 TOP 100</b-btn>
                  <b-btn size="sm" class="mr-1 mb-1" :variant="`outline-${cate.v}`" @click="setForm('review100', cate.c)">리뷰 많은 순 TOP 100</b-btn>
                  <b-btn size="sm" class="mr-1 mb-1" :variant="`outline-${cate.v}`" @click="setForm('recent', cate.c)">지금 가장 많이 보는 상품</b-btn>
                  <b-btn size="sm" class="mr-1 mb-1" :variant="`outline-${cate.v}`" @click="setForm('b_lowest', cate.c)">B 최저가</b-btn>
                  <b-btn size="sm" class="mr-1 mb-1" :variant="`outline-${cate.v}`" @click="setForm('express', cate.c)">발란 익스프레스 추천상품</b-btn>
                </b-card>
              </b-col>
            </b-row>

            <div class="mb-3"></div>
          </b-col>
          <b-col cols="12" xl="4">
            <b-tabs>
              <b-tab title="인기검색어">
                <div v-for="k in searchMeta.keyword" :key="k.dt">
                  <b>{{k.dt}}</b><br/>
                  <template v-for="w in k.words">
                    <span class="pointer badge badge-light fs-13 mr-1 mb-1" :title="w.cnt + '회'" @click="setKeyword(w.word)" :key="w.word">{{w.word}}</span>
                  </template>
                  <hr/>
                </div>
                <a href="/#/data/store/140" target="_blank">기간별 검색어 순위 조회 <i class="fa fa-external-link"></i></a>
              </b-tab>
              <b-tab title="Top Brand">
                <div class="clearfix">
                  <i class="fa fa-copy pull-right pointer"
                     @click="$utils.copyAlert(searchMeta.brand.map(e => e.brand_no).join('\n'), {msg: `${searchMeta.brand.length} 개 브랜드 번호가 복사되었습니다.`})">
                  </i>
                  <b-form class="mb-2" inline>
                    <b>{{form.gm.day}} 일간 판매금액 순</b>
                    <b-form-checkbox class="ml-2" v-model="excludeCancel">반품/취소/품절 제외</b-form-checkbox>
                  </b-form>
                </div>
                <template v-if="brand.length">
                  <div class="mb-1 pointer clearfix" v-for="(b, idx) in searchMeta.brand.slice((searchMeta.brandPage - 1) * searchMeta.limit, searchMeta.brandPage * searchMeta.limit)" @click="setBrand(b)" :key="idx">
                    <div class="pull-right">
                      <b-badge class="fs-12" variant="light" :title="`QTY: ${b.qty}`">SALE: {{$utils.round(b.sales / 1000000, 1)}}백만</b-badge>
                      <!--                    <b-badge class="fs-12" variant="light">QTY: {{b.qty}}</b-badge>-->
                    </div>
                    <b-badge class="fs-13" variant="light">{{(searchMeta.brandPage - 1) * searchMeta.limit + idx + 1}}위</b-badge>
                    <b-badge class="fs-13" variant="warning">{{ brandMap[b.brand_no].brand_nm }} ({{ brandMap[b.brand_no].brand_nm_kr }})</b-badge>
                  </div>
                  <div class="d-flex justify-content-center mt-3">
                    <b-pagination :total-rows="searchMeta.brand.length" :per-page="searchMeta.limit" :limit="10" hide-goto-end-buttons v-model="searchMeta.brandPage"/>
                  </div>
                </template>
              </b-tab>
              <b-tab title="Top Category">
                <div class="clearfix">
                  <i class="fa fa-copy pull-right pointer"
                     @click="$utils.copyAlert(searchMeta.category.map(e => e.category).join('\n'), {msg: `${searchMeta.category.length} 개 카테고리 코드가 복사되었습니다.`})">
                  </i>
                  <b-form class="mb-2" inline>
                    <b>{{form.gm.day}} 일간 판매금액 순</b>
                    <b-form-checkbox class="ml-2" v-model="excludeCancel">반품/취소/품절 제외</b-form-checkbox>
                  </b-form>
                </div>
                <template v-if="category.length">
                  <div class="mb-1 pointer clearfix" v-for="(c, idx) in searchMeta.category.slice((searchMeta.categoryPage - 1) * searchMeta.limit, searchMeta.categoryPage * searchMeta.limit)" @click="setCategory(c)" :key="idx">
                    <div class="pull-right">
                      <b-badge class="fs-12" variant="light" :title="`QTY: ${c.qty}`">SALE: {{$utils.round(c.sales / 1000000, 1)}}백만</b-badge>
                      <!--                    <b-badge class="fs-12" variant="light">QTY: {{c.qty}}</b-badge>-->
                    </div>
                    <b-badge class="fs-13" variant="light">{{(searchMeta.categoryPage - 1) * searchMeta.limit + idx + 1}}위</b-badge>
                    <span class="badge alert-primary fs-13">{{ categoryMap[c.category].path }}</span>
                  </div>
                  <div class="d-flex justify-content-center mt-3">
                    <b-pagination :total-rows="searchMeta.category.length" :per-page="searchMeta.limit" :limit="10" hide-goto-end-buttons v-model="searchMeta.categoryPage"/>
                  </div>
                </template>
              </b-tab>
              <b-tab v-if="$R('DEV')" title="Top Partner">
                <div class="clearfix">
                  <i class="fa fa-copy pull-right pointer"
                     @click="$utils.copyAlert(searchMeta.shop.map(e => e.shop_id).join('\n'), {msg: `${searchMeta.shop.length} 개 Shop ID가 복사되었습니다.`})">
                  </i>
                  <b-form class="mb-2" inline>
                    <b>{{form.gm.day}} 일간 판매금액 순</b>
                    <b-form-checkbox class="ml-2" v-model="excludeCancel">반품/취소/품절 제외</b-form-checkbox>
                  </b-form>
                </div>
                <template v-if="shop.length">
                  <div class="mb-1 clearfix" v-for="(s, idx) in searchMeta.shop.slice((searchMeta.shopPage - 1) * searchMeta.limit, searchMeta.shopPage * searchMeta.limit)" :key="idx">
                    <div class="pull-right">
                      <b-badge class="fs-12" variant="light" :title="`QTY: ${s.qty}`">SALE: {{$utils.round(s.sales / 1000000, 1)}}백만</b-badge>
                      <!--                    <b-badge class="fs-12" variant="light">QTY: {{b.qty}}</b-badge>-->
                    </div>
                    <b-badge class="fs-13" variant="light">{{(searchMeta.brandPage - 1) * searchMeta.limit + idx + 1}}위</b-badge>
                    <b-badge class="fs-13" variant="light">{{s.shop_id}}. {{ shopMap[s.shop_id].boutique }}</b-badge>
                  </div>
                  <div class="d-flex justify-content-center mt-3">
                    <b-pagination :total-rows="searchMeta.shop.length" :per-page="searchMeta.limit" :limit="10" hide-goto-end-buttons v-model="searchMeta.shopPage"/>
                  </div>
                </template>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-collapse>

      <div class="mt-2 text-center">
        <b-button size="lg" class="mr-2" variant="primary" @click="list" :disabled="busy.gm">
          검색<b-spinner class="ml-1" small v-if="busy.gm"></b-spinner>
        </b-button>
        <b-button size="lg" class="mr-2" variant="warning" @click="resetForm">
          초기화
        </b-button>
        <b-button size="lg" class="mr-2" variant="outline-success" v-b-toggle.form>
          검색조건토글
        </b-button>
        <b-button size="lg" class="mr-2" variant="light" @click="openQueryModal">
          Query
        </b-button>
      </div>

      <hr />

      <h6>Look & Feel <b-badge class="pointer" variant="success" v-b-toggle.look>Toggle</b-badge></h6>
      <b-collapse id="look" v-model="collapse.look">
        <b-row class="mb-2">
          <b-col cols="3" lg="2">
            <b-form-checkbox class="col-form-label" v-model="info"> 부가정보</b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="2">
            <b-form-checkbox class="col-form-label" v-model="mobileMode"> 2개씩 보기</b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="2">
            <b-form-checkbox class="col-form-label" v-model="checkMode">
              체크모드
              <i class="fa fa-question-circle" v-b-tooltip="'선택된 상품을 복사 혹은 신고할 수 있습니다'"></i>
            </b-form-checkbox>
          </b-col>
          <b-col cols="6" lg="4">
            <b-form inline>
              이미지 Width
              <b-form-input class="w-90px mx-1 text-center" v-model="imgWidth"></b-form-input>
              px
            </b-form>
          </b-col>
        </b-row>
      </b-collapse>
    </b-card>

    <b-pagination :total-rows="totalRows" :per-page="form.gm.limit" limit="10" align="center" v-model="form.gm.page" fitst-number @change="nextTickList()"/>

    <b-card :style="mobileMode ? 'max-width: 500px; width: 100%; margin: auto;' : ''">
      <div class="clearfix mb-2">
<!--        <b-badge variant="warning" class="pull-right pointer" @click="item.gm = {}">Clear</b-badge>-->
        <div v-if="checkMode" class="pull-right">
          <b-btn class="mr-1" variant="primary" size="sm" @click="reportModal">상품정보수정요청</b-btn>
          <b-btn class="mr-1" variant="success" size="sm" @click="copySelected">Copy ID</b-btn>
          <b-btn class="mr-1" variant="warning" size="sm" @click="selectAll">전체선택</b-btn>
          <b-btn variant="outline-warning" size="sm" @click="clearSelected">Clear</b-btn>
        </div>
        <b-badge class="fs-15">Result</b-badge> <span v-if="item.gm.hits">took: {{item.gm.took}} ms, total: {{item.gm.hits.total.value}}</span>
      </div>
      <template v-if="item.gm.hits">
        <div class="flex-row flex-wrap d-flex justify-content-center">
          <gm-result class="flex-grow-0 position-relative p-1" :style="mobileMode ? 'width: 50%' : 'width: 16.6%'"
                     v-for="e in items.gm"
                     v-bind="{e, shopMap, info, imgWidth, checkMode}"
                     @scoreModal="openScoreModal($event)"
                     @goodsModal="openGoodsModal($event)"
                     :key="e.id"></gm-result>
        </div>
      </template>
    </b-card>

    <b-pagination :total-rows="totalRows" :per-page="form.gm.limit" limit="10" align="center" v-model="form.gm.page" fitst-number @change="nextTickList()"/>

    <b-modal title="쿼리확인 및 커스텀" size="xl" v-model="modal.query" ok-only ok-title="닫기">
      <b-tabs v-model="tabIndex.query">
        <b-tab title="검색조건">
          <b-row>
            <b-col cols="5">
              <codemirror ref="custumQuery" v-model="modalItem.query.custom" @ready="e => e.setSize(null, '600px')"></codemirror>
            </b-col>
            <b-col cols="7">
              <b>검색쿼리 분석</b>
              <div v-for="(e, idx) in explain" :key="idx">
                <b-badge variant="primary">키워드: {{e.chunk}}</b-badge>
                <b-badge variant="light">idx: {{e.idx}}</b-badge>
                <b-badge variant="light">len: {{e.length}}</b-badge>
                <div class="pl-3" v-for="(m, mIdx) in e.msg" :key="mIdx">
                  {{m.text}}
                  <div class="pl-4" v-for="(v, vIdx) in m.values" :key="vIdx">
                    <b-badge variant="secondary">
                      {{v}}
                    </b-badge>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-btn class="mt-2" variant="success" @click="runQuery">이 쿼리로 실행</b-btn>
          <b-btn class="mt-2" variant="light" @click="copyQuery('custom')">Copy Query</b-btn>
        </b-tab>
        <b-tab title="마지막 실행">
          <codemirror ref="lastQuery" v-model="modalItem.query.last" @ready="e => e.setSize(null, '600px')"></codemirror>
          <b-btn class="mt-2" variant="light" @click="copyQuery('last')">Copy Query</b-btn>
        </b-tab>
        <b-tab title="키워드 사전">
          <pre v-html="dictStr"></pre>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal title="Score Factor" size="xl" v-model="modal.score">
      <template v-if="modalItem.score && modalItem.score.score">
        <h5>Sort : {{JSON.stringify(modalItem.score.sort).replace(/,/g, ', ')}}</h5>
        <div>
          검색기준 : {{optionMap.sort[lastBody.gm.sort]}}
          <span v-if="lastBody.gm.sort === 'popular'">({{sortCols.map(e => e.text).join(', ')}})</span>
          <span v-if="lastBody.gm.sort === 'new'">(카탈로그중 최신 발란코드 순)</span>
        </div>

        <br/>

        <h5>Score Factor of Current Goods({{lastBody.gm.day}} days)</h5>
        <table class="table b-table table-sm text-center mb-3">
          <thead>
          <tr>
            <th></th>
            <th v-for="col in sortCols" :key="col.text">
              {{col.text}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>7일</th>
            <td v-for="col in sortCols" :key="col.key">
              {{col.key === 'goods.member_price' ? modalItem.score.goods[0].member_price : modalItem.score.score[col.key]}}
            </td>
          </tr>
          </tbody>
        </table>

        <br/>

        <h5>검색용 필드</h5>
        <small>GM ID(or 발란코드), 소속 발란코드 list, 브랜드명, 브랜드한글명, 상품명, SKU, 공백 및 기호 제거 SKU, 카테고리명, 동의어로 구성됩니다.</small>
        <div class="mt-1">
          <b-badge variant="light" class="mr-1 ellipsis" style="max-width: 50%;" v-for="f in modalItem.score.search_fields" :key="f">{{f}}</b-badge>
        </div>
      </template>
    </b-modal>

    <b-modal title="카탈로그 상품 리스트" size="xl" v-model="modal.goods">
      <div v-if="busy.goodsScore" class="d-flex justify-content-center my-3">
        <b-spinner type="grow" label="Loading..."></b-spinner>
      </div>
      <div v-else class="flex-row flex-wrap d-flex">
        <gm-goods class="flex-grow-0 m-2 position-relative w-200px" v-for="e in modalItem.goods" :key="e.goodsno"
                  v-bind="{e, shopMap, cfMap, day: form.gm.day}">
        </gm-goods>
      </div>
    </b-modal>

    <b-modal title="Report incorrect goods info" v-model="modal.report">
      <div>
        <h6>Send {{this.items.gm.filter(e => e.selected).length}} goods to slack
          <b><a href="https://balaaneer.slack.com/archives/C01H8CUFRSR" target="_blank">#monitor_goods</a></b>
          channel.</h6>
      </div>
      <small>Reason</small>
      <b-form>
        <b-form-radio-group class="col-form-label" v-model="form.report.reason" stacked :options="[
          {text: 'Brand is different', value: 'brand'},
          {text: 'Category is different', value: 'category'},
          {text: 'ETC', value: 'etc'},
        ]"></b-form-radio-group>
        <div v-if="form.report.reason === 'etc'">
          <b-input v-model="form.report.etc" placeholder="the reason why incorrect"></b-input>
        </div>
      </b-form>
      <template v-slot:modal-footer="{cancel}">
        <b-button variant="primary" @click="reportSelected()">
          전송
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import gmResult from '@/views/goods/ES/GMSearchResult.vue'
import gmGoods from '@/views/goods/ES/GMGoods.vue'
import ColorCheckbox from "../../modules/ColorCheckbox.vue";

export default {
  name: 'SearchGM',
  title: '카탈로그 검색',
  components: {ColorCheckbox, gmResult, gmGoods},
  data() {
    return {
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      cfMap: {},
      dict: {},
      dictStr: '',
      excludeBrandNos: [],

      sortCols: [
        {text: '거래액', key: 'pay_amount_7d', sort: '높은순'},
        {text: '주문상품단가', key: 'goods_price_7d', sort: '높은순'},
        {text: 'PV 대비 CVR', key: 'cvr_per_pv_7d', sort: '높은순'},
        {text: '쇼핑백', key: 'cart_7d', sort: '높은순'},
        {text: '위시리스트', key: 'wish_7d', sort: '높은순'},
        {text: 'PV', key: 'pv_7d', sort: '높은순'},
        {text: '상품중최저가', key: 'goods.member_price', sort: '높은순'},
      ],
      scoreCols: 'pay_amount_7d,goods_price_7d,cvr_per_pv_7d,cart_7d,wish_7d,pv_7d'.split(','),
      searchMeta: {
        keyword: [],
        shop: [],
        brand: [],
        category: [],
        limit: 10,
        shopPage: 1,
        brandPage: 1,
        categoryPage: 1,
      },
      defaultForm: {
        gm: {
          day: 7,
          q: '',
          shop: [],
          brand: [],
          category: [],
          colors: [],
          min: '',
          max: '',
          limit: 60,
          page: 1,
          sort: 'popular',
          goodsType: 'ALL',
          usedGrade: this.$C.USED_GRADE.map(e => e.value),
          gmOnly: false,
          rank: false,
          oneday_delivery: false,
          express: false,
          boutiqueMixMode: false,
        },
        report: {
          reason: '',
          etc: '',
        }
      },
      form: {
        gm: {},
        report: {},
      },
      lastBody: {gm: {}},
      item: {gm: {}},
      items: {gm: []},
      busy: {gm: false, gmmore: false, goodsScore: false},
      hasMore: {gm: false},
      ac: {gm: null}, // abortController
      modal: {
        score: false,
        goods: false,
        query: false,
        report: false,
      },
      modalItem: {
        score: {},
        query: {
          custom: '',
          last: '',
        },
        goods: []
      },
      collapse: {
        form: true,
        look: true
      },
      tabIndex: {
        query: 0
      },
      explain: [],
      explainStr: '',
      totalRows: 1,
      info: true,
      mobileMode: false,
      checkMode: false,
      imgWidth: 200,
      excludeCancel: true,

      options: {
        sort: [
          // {text: '없음', value: ''},
          {text: '인기순', value: 'popular'},
          {text: '최신순', value: 'new'},
          {text: '낮은 가격순', value: 'lowPrice'},
          {text: '높은 가격순', value: 'highPrice'},
          {text: '할인율순', value: 'sale'},
          {text: 'rank_sum', value: 'rank_sum'},
          {text: 'best_rank', value: 'best_rank'},

          // 신규 ES 적용항목 : pv_2day,cart_24hour,wish_24hour,review_24hour,sale_24hour,pay_24hour,rank_1hour,rank_1day,rank_1week,rank_1month
          {text: 'PV 2일', value: 'pv_2day'},
          {text: '장바구니 24시간', value: 'cart_24hour'},
          {text: '위시리스트 24시간', value: 'wish_24hour'},
          {text: '리뷰 24시간', value: 'review_24hour'},
          {text: '판매수 24시간', value: 'sale_24hour'},
          {text: '판매금액 24시간', value: 'pay_24hour'},
          {text: '랭킹 1시간', value: 'rank_1hour'},
          {text: '랭킹 1일', value: 'rank_1day'},
          {text: '랭킹 1주', value: 'rank_1week'},
          {text: '랭킹 1개월', value: 'rank_1month'},
        ],
      },
      optionMap: {
        sort: {}
      }
    }
  },
  async created() {
    this.optionMap.sort = this.$utils.arr2map(this.options.sort, 'value', 'text');

    this.resetForm();

    const prms = Promise.all([
      this.getSearchMeta(),
      this.getESAggr(),
      this.getDict(),
      this.getExcludeBrands()
    ]);

    const meta = await this.$api.getMeta('shop,brand,category');
    if (!meta) return;

    this.shop = meta.shop.sort((a, b) => (a.use_yn === 'n' ? 10000 : 0) + a.shop_id - (b.use_yn === 'n' ? 10000 : 0) - b.shop_id);
    this.shop.forEach(s => {
      s.value = s.shop_id;
      s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });

    this.brand = meta.brand.map(e => {
      return this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a, b) => a.label.localeCompare(b.label));

    this.category = meta.category.map(e => {
      return this.categoryMap[e.category] = {...e, value: e.category, label: `${e.category} (${e.category_nm})`};
    }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));
    meta.category.forEach(e => {
      this.categoryMap[e.category].path = Array(e.category.length / 3).fill(0)
        .map((n, i) => this.categoryMap[e.category.substring(0, i * 3 + 3)].category_nm).join(' > ');
    });

    await prms;

    this.list();
  },
  watch: {
    tabIndex: {
      deep: true,
      handler() {
        this.$refs.custumQuery.refresh();
        this.$refs.lastQuery.refresh();
      }
    },
    excludeCancel() {
      this.getESAggr();
    }
  },
  methods: {
    async getDict() {
      this.busy.gm = true;
      const j = await this.$api.getJson('/goods/es/dict');
      this.dict = j.dict;
      this.dictStr = Object.entries(j.dict).map(([k, arr]) => `[${k}]   ${arr.map(e => `  ${e.type}: ${e.text}`).join(', ')}`).join('\n');
      this.busy.gm = false;
    },
    async getExcludeBrands() {
      this.busy.gm = true;
      const j = await this.$api.getJson('/goods/es/excludeBrands');
      this.excludeBrandNos = j.brandNos;
      this.busy.gm = false;
    },
    /**
     * 검색 프리셋용 인기 키워드를 가져온다.
     * @returns {Promise<void>}
     */
    async getSearchMeta() {
      const j = await this.$api.getJson('/goods/es/searchMeta');
      this.searchMeta.keyword = j.data.keyword;
    },
    /**
     * 검색 프리셋용 인기 브랜드, 카테고리를 가져온다.
     * @returns {Promise<void>}
     */
    async getESAggr() {
      this.$api.postJson('/goods/es/elasticCloud', {index: 'bl_order', query: this.makeOrderStatQuery('category', this.excludeCancel)}).then(res => {
        this.searchMeta.category = res.data.aggregations.group.buckets.map(e => ({category: e.key, sales: e.sales.value, qty: e.qty.value}));
      });
      this.$api.postJson('/goods/es/elasticCloud', {index: 'bl_order', query: this.makeOrderStatQuery('brand_no', this.excludeCancel)}).then(res => {
        this.searchMeta.brand = res.data.aggregations.group.buckets.map(e => ({brand_no: e.key, sales: e.sales.value, qty: e.qty.value}));
      });
      this.$api.postJson('/goods/es/elasticCloud', {index: 'bl_order', query: this.makeOrderStatQuery('shop_id', this.excludeCancel)}).then(res => {
        this.searchMeta.shop = res.data.aggregations.group.buckets.map(e => ({shop_id: e.key, sales: e.sales.value, qty: e.qty.value}));
      });
    },
    makeOrderStatQuery(groupBy, excludeCancel = false) {
      const esQuery = {
        query: {
          bool: {
            filter: [
              {
                range: {
                  order_date: {
                    gte: this.$utils.kstD(this.$moment().add(-this.form.gm.day, 'day'))
                  }
                }
              }
            ]
          }
        },
        size: 0,
        aggs: {
          group: {
            terms: {
              field: groupBy,
              order: {
                sales: 'desc'
              },
              size: 100
            },
            aggs: {
              sales: {
                sum: {
                  field: 'sales_price',
                }
              },
              qty: {
                sum: {
                  field: 'qty'
                }
              }
            }
          },
        },
      };
      if (excludeCancel) {
        esQuery.query.bool.must_not = [{terms: {order_status: ['취소확인', '반품완료', '품절']}}];
      }
      return esQuery;
    },
    setKeyword(w) {
      this.form.gm.q = w;
      this.list();
    },
    setShop(s) {
      this.form.gm.shop = [this.shopMap[s.shop_id]];
      this.list();
    },
    setBrand(b) {
      this.form.gm.brand = [this.brandMap[b.brand_no]];
      this.list();
    },
    setCategory(c) {
      this.form.gm.category = [this.categoryMap[c.category]];
      this.list();
    },
    resetForm() {
      this.form.gm = this.$utils.clone(this.defaultForm.gm);
    },
    /**
     * 발란몰의 각 구좌에 맞게 쿼리를 세팅한다.
     */
    setForm(section, cate) {
      this.resetForm();
      this.form.gm.category = this.category.filter(e => e.category === cate);
      const sortKey = {
        ranking: 'rank_1day',
        cart100: 'cart_24hour',
        wish100: 'wish_24hour',
        review100: 'review_24hour',
        recent: 'rank_1day',
        b_lowest: 'popular',
        express: 'popular',
      }[section];
      this.form.gm.sort = sortKey;
      if (section === 'b_lowest') {
        this.form.gm.rank = true;
      } else if (section === 'express') {
        this.form.gm.express = true;
      }
      this.list();
    },
    openQueryModal() {
      this.modalItem.query.custom = JSON.stringify(this.makeESQuery(), null, 2);
      this.modal.query = true;
      setTimeout(() => {
        this.$refs.custumQuery.refresh();
        this.$refs.lastQuery.refresh();
      }, 0)
    },
    copyQuery(type) {
      this.$utils.copyAlert(this.modalItem.query[type]);
    },
    runQuery() {
      this.list(true);
      this.modal.query = false;
    },
    makeESQuery(custom) {
      if (custom === true) {
        return JSON.parse(this.modalItem.query.custom);
      }
      // 조건이 하나라도 있다면 query.bool 부터 시작
      const form = this.form.gm;
      const esQuery = {query: {bool: {must: [], must_not: [], filter: []}}, from: (form.page - 1) * form.limit, size: form.limit};
      const bool = esQuery.query.bool;
      const goodsFilter = [];

      if (form.q.trim()) {
        const [must, explain] = this.parseSearchQuery(form.q);
        bool.must = bool.must.concat(must);
        this.explain = explain;
        this.makeExplainStr();
        // bool.must.push({match: {search_fields: form.q.trim()}});
      } else {
        this.explain = [];
        this.explainStr = '';
      }
      if (form.shop.length) bool.filter.push({terms: {'goods.shop_id': form.shop.map(e => e.shop_id)}});
      if (form.category.length) bool.filter.push({terms: {category: form.category.map(e => e.category)}});
      if (form.brand.length) bool.filter.push({terms: {'brand.id': form.brand.map(e => e.brand_no)}});
      if (form.gmOnly) bool.filter.push({term: {type: 'master'}});
      // if (form.rank) bool.filter.push({term: {'is_lowest_goods.flag': 1}});
      if (form.express) bool.filter.push({term: {express: 1}});
      if (form.colors.length) bool.filter.push({terms: {major_color: form.colors}});

      // nested
      if (form.goodsType !== 'ALL') goodsFilter.push({term: {'goods.goods_status': form.goodsType[0].toUpperCase()}});
      if (form.goodsType === 'used') goodsFilter.push({terms: {'goods.used_grade': form.usedGrade}});
      if (form.rank) goodsFilter.push({term: {'goods.b_lowest_price': 1}});
      if (form.oneday_delivery) goodsFilter.push({term: {'goods.oneday_delivery': 1}});
      if (form.min || form.max) {
        const range = {};
        if (form.min) range.gte = form.min;
        if (form.max) range.lte = form.max;
        goodsFilter.push({range: {'goods.member_price': range}});
      }
      if (goodsFilter.length) bool.filter.push({nested: {path: 'goods', query: {bool: {filter: goodsFilter}}}});

      if (['rank_1hour', 'rank_1day', 'rank_1week', 'rank_1month'].includes(this.form.gm.sort)) {
        bool.must_not.push({term: {'brand.id': 5240}});
      }

      // 인기순 정렬이 아닐 때 브랜드 제외
      if (this.form.gm.sort !== 'popular' && this.excludeBrandNos.length) {
        esQuery.query.bool.must_not.push({terms: {'brand.id': this.excludeBrandNos}});
      }

      if (bool.must.length === 0) delete bool.must;
      if (bool.must_not.length === 0) delete bool.must_not;
      if (bool.filter.length === 0) delete bool.filter;
      if (Object.keys(bool).length === 0) delete esQuery.query;

      const sortPreset = {
        'new': {
          'goods.goodsno': {
            'mode': 'max',
            'order': 'desc',
            'nested': {
              'path': 'goods',
              ...(goodsFilter.length && {filter: {bool: {filter: goodsFilter}}})
            }
          }
        },
        'lowPrice': {
          'goods.member_price': {
            'mode': 'min',
            'order': 'asc',
            'nested': {
              'path': 'goods',
              ...(goodsFilter.length && {filter: {bool: {filter: goodsFilter}}})
            }
          }
        },
        'highPrice': {
          'goods.member_price': {
            'mode': 'max',
            'order': 'desc',
            'nested': {
              'path': 'goods',
              ...(goodsFilter.length && {filter: {bool: {filter: goodsFilter}}})
            }
          }
        },
        'sale': {
          'goods.sale_percent': {
            'mode': 'max',
            'order': 'desc',
            'nested': {
              'path': 'goods',
              ...(goodsFilter.length && {filter: {bool: {filter: goodsFilter}}})
            }
          }
        },
        'popular': [
          {'score.pay_amount_7d': 'desc'},
          {'score.goods_price_7d': 'desc'},
          {'score.cvr_per_pv_7d': 'desc'},
          {'score.cart_7d': 'desc'},
          {'score.wish_7d': 'desc'},
          {'score.pv_7d': 'desc'},
          {
            'goods.member_price': {
              'mode': 'min',
              'order': 'asc',
              'nested': {'path': 'goods', ...(goodsFilter.length && {filter: {bool: {filter: goodsFilter}}})}
            }
          }
        ],
        'pv_2day': {'pv_2day': 'desc'},
        'cart_24hour': {'cart_24hour': 'desc'},
        'wish_24hour': {'wish_24hour': 'desc'},
        'review_24hour': {'review_24hour': 'desc'},
        'sale_24hour': {'sale_24hour': 'desc'},
        'pay_24hour': {'pay_24hour': 'desc'},
        'rank_sum': {'rank_sum': 'asc'},
        'best_rank': {'best_rank': 'asc'},
        'rank_1hour': {'rank_1hour': 'asc'},
        'rank_1day': {'rank_1day': 'asc'},
        'rank_1week': {'rank_1week': 'asc'},
        'rank_1month': {'rank_1month': 'asc'},
      }
      esQuery.sort = sortPreset[this.form.gm.sort];

      return esQuery;
    },
    makeExplainStr() {
      this.explainStr = this.explain.map(e => `키워드: ${e.chunk}, idx: ${e.idx}, len: ${e.length}\n${e.msg.map(m => {
          const values = m.values ? m.values.join(', ') : '';
          return `  ${m.text}${values ? ': ' + values : ''}`;
        }).join('\n')}`
      ).join('\n');
    },
    /**
     * b-pagination 에서 @change 에 바로 list 를 호출하면 이전 페이지번호 기준으로 실행된다.
     * nextTick 에 실행하도록 한다.
     */
    async nextTickList() {
      this.$nextTick(() => { this.list(); });
    },
    async list(custom) {
      this.busy.gm = true;
      this.lastBody.gm = this.$utils.clone(this.form.gm);

      const query = this.makeESQuery(custom);
      this.modalItem.query.last = JSON.stringify(this.$utils.clone(query), null, 2);

      if (!query.query) query.query = {bool: {must: []}};
      if (!query.query.bool.must) query.query.bool.must = [];

      // 부티크 혼합모드
      if (this.form.gm.boutiqueMixMode) {
        const partSize = query.size / 2; // 개별 파트의 사이즈, 60 -> 30
        const partFrom = query.from / 2; // 개별 파트의 from, 600 -> 300
        query.size = partSize;
        query.from = partFrom;
        const nonBoutiqueQuery = this.$utils.clone(query);
        query.query.bool.must.push({term: {is_boutique: 1}});
        nonBoutiqueQuery.query.bool.must.push({term: {is_boutique: 0}});

        // 각각의 쿼리로 데이터 추출
        let [boutiqueRaw, nonBoutiqueRaw] = await Promise.all([
          this.$api.postJson('/goods/es/elasticCloud', {index: 'bl_gm', query}),
          this.$api.postJson('/goods/es/elasticCloud', {index: 'bl_gm', query: nonBoutiqueQuery}),
        ]);
        if (!boutiqueRaw || !nonBoutiqueRaw) {
          this.busy.gm = false;
          return;
        }
        boutiqueRaw = boutiqueRaw.data;
        nonBoutiqueRaw = nonBoutiqueRaw.data;
        const boutiqueCount = boutiqueRaw.hits.total.value;
        const nonBoutiqueCount = nonBoutiqueRaw.hits.total.value;

        if (boutiqueRaw.hits.hits.length < query.size / 2 && nonBoutiqueRaw.hits.hits.length >= query.size / 2) {
          const moreCount = partFrom + partSize - boutiqueCount;
          nonBoutiqueQuery.from = Math.max(partFrom + moreCount - partSize, 0);
          nonBoutiqueQuery.size = Math.min(partSize * 2, partSize + moreCount);
          nonBoutiqueRaw = (await this.$api.postJson('/goods/es/elasticCloud', {index: 'bl_gm', query: nonBoutiqueQuery})).data;
        } else if (nonBoutiqueRaw.hits.hits.length < query.size / 2 && boutiqueRaw.hits.hits.length >= query.size / 2) {
          const moreCount = partFrom + partSize - nonBoutiqueCount;
          query.from = Math.max(partFrom + moreCount - partSize, 0);
          query.size = Math.min(partSize * 2, partSize + moreCount);
          boutiqueRaw = (await this.$api.postJson('/goods/es/elasticCloud', {index: 'bl_gm', query})).data;
        }

        const total = boutiqueCount + nonBoutiqueCount;
        const hits = [];
        for (let i = 0; i < Math.max(boutiqueRaw.hits.hits.length, nonBoutiqueRaw.hits.hits.length); i++) {
          nonBoutiqueRaw.hits.hits[i] && hits.push(nonBoutiqueRaw.hits.hits[i]);
          boutiqueRaw.hits.hits[i] && hits.push(boutiqueRaw.hits.hits[i]);
        }

        boutiqueRaw.hits.total.value = total;
        boutiqueRaw.hits.hits = hits;
        this.item.gm = boutiqueRaw;
      } else {
        // 전체상품모드
        this.item.gm = (await this.$api.postJson('/goods/es/elasticCloud', {index: 'bl_gm', query})).data;
      }
      this.busy.gm = false;
      this.totalRows = this.item.gm.hits.total.value;

      // sort 값을 정비한다.
      // sort 에 해당하는 필드값이 null 이면 극단값인 -9223372036854776000 등으로 교체되어 정렬된다.
      // 오해의 소지가 있으므로 100억을 넘기면 0 으로 교체한다.
      this.item.gm.hits.hits.forEach(e => {
        e.sort = e.sort.map(s => s > 10000000000 || s < -10000000000 ? 0 : s);
        e._source.goods.forEach(g => g.score = {});
      });
      this.items.gm = this.item.gm.hits.hits.map(e => ({...e._source, sort: e.sort, selected: false}));

      const targetGoodsNos = this.item.gm.hits.hits.map(e => e._source.goods.map(g => g.goodsno)).flat().set().filter(e => !this.cfMap[e]);
      if (targetGoodsNos.length === 0) {
        return;
      }
      const cfs = await this.$api.postJson('/goods/es/confirmed', {goodsNos: targetGoodsNos});
      Object.assign(this.cfMap, this.$utils.arr2map(cfs.list, 'goods_no'));

      this.$forceUpdate();
    },
    openScoreModal(e) {
      this.modalItem.score = e;
      this.modal.score = true;
    },
    openGoodsModal(goods) {
      this.modalItem.goods = goods;
      this.modal.goods = true;
      this.busy.goodsScore = true;
      this.$api.postJson('/goods/es/scoreFactor', {goodsNos: goods.map(e => e.goodsno), day: this.form.gm.day}).then(j => {
        const scoreMap = this.$utils.arr2map(j.data, 'goodsno');
        this.modalItem.goods.forEach(g => g.score = scoreMap[g.goodsno] || {});
        this.busy.goodsScore = false;
        // setTimeout(() => {
        //   this.$forceUpdate();
        // }, 0);
      });
    },


    /**
     * 검색어를 의미단위에 맞게 사전을 기준으로 쪼갠다.
     * 띄어쓰기가 없어도 쪼개는 것을 목표로 한다.
     * godo_api 와 동일하게 맞춘다.
     *
     * @param {string} q
     * @return {[object[], object[]]}
     */
    parseSearchQuery(q) {
      if (!q) return [];
      // [asis] es 에서 분리해서 저장하는 기준.
      // const re = /[\s\-\]\\`~!@#$%^&*()=+[{}|;",<>/?°]+/g;
      // [tobe] es 에서 분리해서 저장하는 기준. '.' 의 동작 특수성 때문에 복잡해졌다.
      const re = /[\s\-\]\\`~!@#$%^&*()=+[{}|;",<>/?°]+|[\s\-\]\\`~!@#$%^&*()=+[{}|;",<>/?°.]+\.+|\.+[\s\-\]\\`~!@#$%^&*()=+[{}|;",<>/?°.]+/g;
      /*
        '.' 의 동작 특수성
        - 숫자 앞뒤로 붙는 경우 : a.02 는 a 02 처럼 동작하고 d0.e 는 d0 e 처럼 동작한다. x.y 는 x.y 대로 동작한다.
        - es 분리기호 앞뒤로 붙는 경우: x. y 는 x y 처럼 동작한다(. 을 무시).
        - 자체로 2개 이상일 때: x..y 는 x y 처럼 동작한다.
        - 즉 . 의 앞뒤로 es 분리기호나 숫자나 . 이 하나라도 있으면 분리기호가 되고, 없으면 문자가 된다.
       */
      let search = q.trim();
      if (!search) return [];

      /*
         특수 조건에 만족한다면, 별다른 전처리 없이 그대로 활용한다
         - 숫자로만 구성된 경우 : 발란코드, sku, ...
         - 영숫자, -, 띄어쓰기로 구성된 경우 : sku, ...
       */
      if (search.match(/^\d+$/)) {
        return [
          [{match: {search_fields: search}}], // must
          [{chunk: search, idx: 0, length: search.length, msg: [
              {text: `전체 검색어가 숫자입니다. 발란코드, SKU 등에 해당하여 바로 검색합니다`, values: [search]}
            ]}] // explain
        ];
      }
      if (search.split(/\s+/).length >= 5) { // 5단어 이상의 검색은 의도가 아닌 상품명 그 자체를 의미한다.
        return [
          [{
            nested: {
              path: 'goods', query: {
                bool: {
                  filter: [{
                    match: {'goods.goodsnm': search}
                  }]
                }
              }
            }
          }], // must
          [{
            chunk: search, idx: 0, length: search.length, msg: [
              {text: `검색어가 5단어 이상입니다. 상품명에 해당하여 바로 검색합니다`, values: [search]}
            ]
          }] // explain
        ];
      }
      // if (search.match(/^[-\w\s]+$/)) {
      //   return [
      //     [{match: {search_fields: search}}], // must
      //     [{chunk: search, idx: 0, length: search.length, msg: [
      //         {text: `전체 검색어가 영문, 숫자, -_, 공백 입니다. SKU 등에 해당하여 바로 검색합니다`, values: [search]}
      //       ]}] // explain
      //   ];
      // }

      search = search.toUpperCase().replace(/(\d)\./, '$1 ').replace(/\.(\d)/, ' $1');
      const dict = this.dict;
      const must = [];
      const explain = [];

      const tokens = search.split(re);
      const splitter = search.match(re);
      const getOrgIndex = idx => {
        let tokenLen = 0;
        let splitterLen = 0;
        for (let i = 0; i < tokens.length; i++) {
          tokenLen += tokens[i].length;
          if (idx < tokenLen) break;
          splitterLen += splitter[i].length;
        }
        return idx + splitterLen;
      };

      const joinWord = search.split(re).join('');
      // 2 ~ 36 자 까지 잘라서 비교 후 없으면 index 를 증가하며 비교한다. 긴 순서부터 진행한다.
      // 36 자는 가장 긴 사전단어(브랜드)인 ANDREAS KRONTHALER FOR VIVIENNE WESTWOOD 기준이다.
      // 찾으면 잘라서 must 에 넣는다. 만약 첫 단어는 매칭되지 않았다면 중간단어가 매칭될 때 첫 단어도 넣어준다. ex) 가가구찌 => 가가, 구찌
      let idxStart = 0;
      let hasBrand = false; // 나이키 x 제이크루 등의 상품명은 둘 다 브랜드라서 and 조건으로 들어가지 않도록 브랜드는 하나만 넣어야 한다.
      let hasCategory = false; // 카테고리도 마찬가지로 and 로 들어가면 안된다.
      for (let idx = 0; idx < joinWord.length; idx++) {
        for (let i = Math.min(36, joinWord.length - idx); i >= 2; i--) {
          const chunk = joinWord.slice(idx, idx + i);
          let obj = dict[chunk];
          if (obj) {
            const explainObj = {chunk, idx, length: i, msg: []};
            explain.push(explainObj);
            explainObj.msg.push({text: `키워드를 사전에서 발견했습니다.`});
            // 최초부터 일치하지 않고 중간 단어부터 일치한다면
            if (idx - idxStart > 0) {
              // 443497UM8AN1000 등의 sku 가 100 이라는 브랜드에 의해 쪼개지는 것을 방지하기 위해, 중간부터 검색이자 최초 사전매칭이라면 전체를 키워드로 검색하도록 리턴한다.
              // 이렇게 할 때 ugg boots 등이 쪼개지지 않는 문제는 해결되지만
              // 10002347 등의 sku 가 100 에 의해 쪼개지는건 막을 수 없다.
              if (search.match(/^[-\w\s]+$/)) {
                return [
                  [{match: {search_fields: joinWord}}], // must
                  [{chunk: joinWord, idx: 0, length: joinWord.length, msg: [
                      {text: `중간 단어[${chunk}]가 사전에서 발견되었지만, 전체 검색어가 영문, 숫자, -_, 공백 이고, 앞쪽 단어가 사전에 없어서 브랜드, 카테고리, 키워드가 아닌 것으로 추정됩니다. SKU 등에 해당하여 바로 검색합니다`, values: [joinWord]}
                    ]}] // explain
                ];
              }
              const befWord = joinWord.slice(idxStart, idx);
              // sku 등 영문, 숫자로만 되어있다면 붙여서 검색한다. 구찌 GG xxx 123 등에 대응
              if (befWord.match(/^[- \w]+$/)) {
                must.push({match: {search_fields: befWord}});
                explainObj.msg.push({text: `SKU 등 영문, 숫자, 구분기호 로만 되어있다면 붙여서 검색합니다`});
              } else { // sku 등이 아니라면, 사전에 없는 단어가 상품명에 활용되는 것을 고려해 스페이스 등의 기호를 복구한다.
                const t = search.slice(getOrgIndex(idxStart), getOrgIndex(idx));
                must.push({match: {search_fields: t}});
                explainObj.msg.push({text: `스페이스 등을 복구해서 검색필드에서 검색합니다`});
              }
            }
            if (obj.length > 1) { // 사전 중복 매칭 관리
              explainObj.msg.push({text: `키워드에 해당하는 사전값이 여러개입니다.`});
              const should = [];
              if (!hasBrand && obj.some(e => e.brand_no)) { // 브랜드에 대한 직접검색, ex) 제이린드버그 검색시 ES 사전에 의해 제이 캣 등도 검색된다.
                should.push({terms: {'brand.id': obj.map(e => e.brand_no).filter(e => e)}});
                explainObj.msg.push({text: `키워드의 사전값중 일부가 브랜드입니다. OR 로 검색합니다`, values: obj.map(e => this.brandMap[e.brand_no]).filter(e => e).map(e => e.label)});
                hasBrand = true;
              }
              obj = obj.filter(e => !e.brand_no);

              if (!hasCategory && obj.some(e => e.category)) { // 카테고리에 대한 직접검색, ex) 토트 백 검색시 ES 사전에 의해 백 이 검색된다.
                // catnm: chunk 로 직접 검색해도 되지만 샌들 / 슬리퍼 같은 카테고리에 대응되지 않는다.
                should.push({terms: {category: obj.map(e => e.category).filter(e => e)}});
                explainObj.msg.push({text: `키워드의 사전값중 일부가 카테고리입니다. OR 로 검색합니다`, values: obj.map(e => this.categoryMap[e.category]).filter(e => e).map(e => e.path)});
                hasCategory = true;
              }
              obj = obj.filter(e => !e.category);

              if (obj.some(e => e.synonym)) { // 동의어 규칙이 있다면 동의어 중 첫 번째 것으로 치환한다(동의어 중복 저장은 안되니 1개만 있어야 한다).
                const synonym = obj.filter(e => e.synonym)[0].synonym;
                should.push({match: {search_fields: synonym}});
                explainObj.msg.push({text: `동의어[${synonym}]가 발견되었습니다. 동의어로 치환합니다. 이후 해당 키워드와 매칭된 ${obj.length - 1} 건은 무시합니다.`, values: [synonym]});
                // 동의어 매칭이 되었으므로 해당 단어에 대해 추가적인 or 를 할 필요가 없다
              } else {
                if (obj.length) {
                  should.push({match: {search_fields: obj.map(e => e.text).set().join(' ')}});
                  explainObj.msg.push({text: `키워드의 사전값중 일부가 브랜드/카테고리가 아닙니다. OR 로 검색합니다`, values: obj.map(e => e.text).set()});
                }
              }

              must.push({bool: {should, minimum_should_match: 1}});
            } else {
              if (!hasBrand && obj[0].brand_no) { // 브랜드에 대한 직접검색, 1개 브랜드만 매칭된 경우
                must.push({term: {'brand.id': obj[0].brand_no}});
                explainObj.msg.push({text: `해당 키워드가 브랜드의 키워드입니다. 유일한 브랜드로 설정합니다.`});
                hasBrand = true;
              } else if (!hasCategory && obj[0].category) { // 카테고리에 대한 직접검색, 1개 카테고리만 매칭된 경우
                must.push({term: {'category': obj[0].category}});
                explainObj.msg.push({text: `해당 키워드가 카테고리의 키워드입니다. 유일한 카테고리로 설정합니다.`});
                hasCategory = true;
              } else if (obj[0].synonym) {
                must.push({match: {search_fields: obj[0].synonym}});
                explainObj.msg.push({text: `동의어[${obj[0].synonym}]가 발견되었습니다. 동의어로 치환합니다.`, values: [obj[0].synonym]});
              } else {
                must.push({match: {search_fields: obj[0].text}});
                explainObj.msg.push({text: `키워드로 검색합니다.`});
              }
            }

            idx = idx + i - 1;
            idxStart = idx + 1;
            break;
          }
        }
      }
      if (idxStart < joinWord.length) {
        // 남는 단어가 있다면, 스페이스 등의 기호를 복구하는 것을 고려한다.
        // 그러나 sku 등 영문, 숫자로만 되어있다면 붙여서 검색한다.
        const aftWord = joinWord.slice(idxStart);
        const explainObj = {chunk: aftWord, idx: idxStart, length: aftWord.length, msg: []};
        explain.push(explainObj);
        if (aftWord.match(/^[- \w]+$/)) {
          // sku 등 영문, 숫자로만 되어있다면 붙여서 검색한다.
          must.push({match: {search_fields: aftWord}});
          explainObj.msg.push({text: `키워드가 사전에서 발견되지 않았습니다. SKU 등 영문, 숫자, 구분기호 로만 되어있다면 붙여서 검색합니다`, values: [aftWord]});
        } else {
          must.push({match: {search_fields: search.slice(getOrgIndex(idxStart))}});
          explainObj.msg.push({text: `키워드가 사전에서 발견되지 않았습니다. 스페이스 등을 복구해서 검색필드에서 검색합니다`, values: [aftWord]});
        }
      }
      return [must, explain];
    },
    reportModal() {
      if (this.items.gm.filter(e => e.selected).length === 0) return alert('수정요청할 상품을 선택해주세요');
      this.form.report = this.$utils.clone(this.defaultForm.report);
      this.modal.report = true;
    },
    async reportSelected() {
      if (!this.form.report.reason) return alert('Please select a reason');
      if (this.form.report.reason === 'etc' && !this.form.report.etc.trim()) return alert('Please enter a reason');
      if (!confirm('Send report request to slack channel?')) return;
      const j = await this.$api.postJson('/goods/es/report', {
        ids: this.items.gm.filter(e => e.selected).map(e => e.id),
        reason: this.form.report.reason,
        etc: this.form.report.etc,
        q: this.form.gm.q
      });
      if (j) {
        this.$alertTop('Report sended');
        this.modal.report = false;
      }
    },
    copySelected() {
      this.$utils.copyAlert(this.items.gm.filter(e => e.selected).map(e => e.id).join('\n'));
    },
    selectAll() {
      this.items.gm.forEach(e => e.selected = true);
    },
    clearSelected() {
      this.items.gm.forEach(e => e.selected = false);
    },
    showQueryModal() {
      this.modal.query = true;
    },
    toggleUsedGrade(grade) {
      if (!grade) {
        this.form.gm.usedGrade = this.form.gm.usedGrade.length === this.$C.USED_GRADE.length ? [] : this.$C.USED_GRADE.map(e => e.value);
      } else {
        this.form.gm.usedGrade = this.$C.USED_GRADE.filter(e => e.value[0] === grade).map(e => e.value);
      }
    },
  }
}
</script>

<style scoped>

</style>
