<template>
  <b-dropdown :size="size" variant="light">
    <template #button-content>
      <template v-if="colors.length === 0 || colors.length === $C.COLORS.length">
        All Colors
      </template>
      <template v-else>
        <div v-for="c in colors.slice(0, 3)" class="d-inline-block" :key="c"
             :style="{background: c === 'multi' ? 'linear-gradient(135deg, red,orange,yellow,green,blue,indigo,violet)' : c,
                      marginBottom: '-4px', width:'18px', height:'18px', border: '1px solid #ccc'}"
             v-b-tooltip="c.name_ko"></div>
        <div v-if="colors.length > 3" class="d-inline-block ml-1">외 {{colors.length - 3}}개</div>
      </template>
    </template>
    <b-dropdown-form class="py-2" :style="{width: !$S.breakpoint.isLteXs ? '430px' : '300px'}">
      <b-button class="" size="sm" variant="primary"
                @click="toggleAll">전체</b-button>
      <b-form-checkbox-group class="mt-2" name="group" v-model="colors">
        <b-row>
          <b-col v-for="c in $C.COLORS" sm="4" cols="6" :key="c.name">
            <b-form-checkbox class="mr-0" :value="c.name">
              <div class="d-inline-block"
                   :style="{background: c.name === 'multi' ? 'linear-gradient(135deg, red,orange,yellow,green,blue,indigo,violet)' : '#' + c.color,
                            width:'22px', height:'22px', border: '1px solid #ccc'}"
                   v-b-tooltip="c.name_ko"></div>
              <div class="d-inline-block va-inherit ml-1">{{ c.name_ko }}</div>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form-checkbox-group>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
export default {
  name: 'ColorCheckboxDropdown',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Array,
    size: {type: String, default: 'sm'}
  },
  computed: {
    colors: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {
    toggleAll() {
      if (this.colors.length === this.$C.COLORS.length) {
        this.colors = [];
      } else {
        this.colors = this.$C.COLORS.map(e => e.name);
      }
    }
  }
}
</script>
