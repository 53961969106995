<template>
  <div>
    <b-checkbox v-if="checkMode" v-model="e.selected"></b-checkbox>
    <div class="position-relative">
      <a :href="MALL_URL + '/m2/goods/view.php?goodsno=' + e.goods[0].goodsno" target="_blank">
        <img :src="e.img_i.split('|')[0].replace('.jpg', '_200.webp')" style="width: 100%; display: block; margin: auto" :style="{maxWidth: `${imgWidth}px`}">
      </a>
      <!-- 좌상단 정보 -->
      <div class="position-absolute" style="top: 0;left: 0;line-height: 15px">
        <template v-if="info && e.score">
          <b-badge variant="light" title="PayAmount AVG">AMT: {{$utils.round($utils.nvl(e.score[`pay_amount_${day}d`], 0) / 1000000, 1)}}M</b-badge><br/>
          <b-badge variant="light" title="GoodsPrice AVG">PRI: {{e.score[`goods_price_${day}d`] ? `${$utils.round(e.score[`goods_price_${day}d`] / 1000, 1)}K` : ''}}</b-badge><br/>
          <b-badge variant="light" title="CVR per PV">CVR/PV: {{e.score[`cvr_per_pv_${day}d`]}}</b-badge><br/>
          <b-badge variant="light" title="Cart AVG">CA: {{$utils.round($utils.nvl(e.score[`cart_${day}d`], 0), 1)}}</b-badge><br/>
          <b-badge variant="light" title="Wishlist AVG">WI: {{$utils.round($utils.nvl(e.score[`wish_${day}d`], 0), 1)}}</b-badge><br/>
          <b-badge variant="light" title="PV AVG">PV: {{e.score[`pv_${day}d`]}}</b-badge><br/>
        </template>
        <template v-if="info && e.type === 'master'">
          <b-badge class="pointer" variant="dark" title="상품수" @click="$emit('goodsModal', e.goods)">GM: {{e.goods.length}}</b-badge><br/>
        </template>
      </div>
      <!-- 우상단 정보 -->
      <div v-if="info" class="position-absolute text-right" style="top: 0;right: 0;line-height: 15px">
        <a v-if="e.type === 'master'" :href="`/#/master/list/${e.id}`" target="_blank" class="badge badge-primary">{{e.id}} <i class="fa fa-external-link"></i></a>
        <a v-else :href="`/#/goods/${e.id}`" target="_blank" class="badge badge-success">{{e.id}} <i class="fa fa-external-link"></i></a>
        <br/>
        <template v-if="e.sku_id">
          <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(e.sku_id)}`"
             v-b-tooltip="e.sku_id"
             class="badge badge-success" target="_blank">
            Naver 검색 <i class="fa fa-external-link"></i>
          </a><br/>
        </template>
      </div>
      <!-- 좌하단 정보 -->
      <div v-if="info" class="position-absolute" style="left: 0;bottom: 0;line-height: 15px">
        <template v-if="e.is_boutique">
          <span class="badge badge-success">부티크 최저가</span><br/>
        </template>
        <template v-if="e.b_lowest_price === 1">
          <span class="badge badge-dark mr-1">B 최저가</span>
        </template>
        <template v-if="e.express">
          <span class="badge badge-info mr-1">Express</span>
        </template>
      </div>
      <!-- 우하단 정보 -->
      <div v-if="info" class="position-absolute text-right" style="right: 0;bottom: 0;line-height: 15px">
        <b-badge variant="primary" class="pointer" @click="$emit('scoreModal', e)">Score: {{Math.round(e.sort[0])}}</b-badge><br/>
        <b-badge variant="secondary" class="pointer" @click="$modal.show({title: 'JSON 보기', item: e, type: 'json'})">JSON</b-badge><br/>
      </div>
    </div>

    <div class="info_box_wrap mb-2">
      <div v-if="info" class="clearfix">
        <div class="pull-right" v-if="e.goods.length === 1">
          <b-badge variant="success">{{e.goods[0].shop_id}}. {{shopMap[e.goods[0].shop_id].boutique}}</b-badge>
          <span :class="`badge alert-${e.goods[0].delivery_type === '국내' ? 'info' : 'success'}`">{{e.goods[0].delivery_type}}</span>
        </div>
        <b-badge variant="warning">{{e.brand.name}}</b-badge>
      </div>
      <div v-else class="bold">{{e.brand.name}}</div>
      <div class="goodsnm" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" :title="e.goodsnm">{{e.goodsnm}}</div>
      <div class="price_info_wrap" style="display: inline-flex;">
        <div v-if="e.goods[0].member_price < e.goods[0].consumer" class="sale_percent mr-2" style="color: #bd081c;font-weight: bold;">{{Math.round(100 - e.goods[0].member_price / e.goods[0].consumer * 100)}}%</div>
        <div class="mr-2 price" style="font-weight: bold;color: #020202;">{{e.goods[0].member_price.comma()}}</div>
        <div class="no_sale_price" style="color: #bdbdbd;text-decoration: line-through;">{{e.goods[0].consumer.comma()}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GMResult',
  props: ['e', 'shopMap', 'info', 'imgWidth', 'checkMode'],
  data() {
    return {
      day: 7
    }
  },
  async created() {
  },
  methods: {
  }
}
</script>
